import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-agent-product',
  templateUrl: './agent-product.component.html',
  styleUrls: ['./agent-product.component.css']
})
export class AgentProductComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  productFilterFormGroup: FormGroup;
  productTypeTermLifeId: string
  dataSource: any[];
  cartItem: any;
  displayedColumns: string[] = ['company', 'companyName', 'paymentFrequency', 'paymentMode', 'minAge', 'maxAge', 'minPolicyTerm', 'maxPolicyTerm','action'];
  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
 
    public _dialog: MatDialog, private _common: CommonService, private _snackbar: MatSnackBar) {

    route.params.subscribe((data: any) => {
     
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
     
      this._common.setActiveTab(data.id);
    });
    this.productTypeTermLifeId = this._common.productTypeTermLifeId;
   
  }

  ngOnInit(): void {
    this.GetProductsByProductTypeForAgent()
  }
  //Get List of product by product type for agent
  GetProductsByProductTypeForAgent() {
    
    this.blockUI.start();
  //this.agentId="61383121-7be0-ea11-a813-0022489d8bbc";
    this._apiService.GetProductsByProductTypeForAgent(this.agentId, this.productTypeTermLifeId).subscribe((data: any) => {
      
      this.blockUI.stop();
      this.dataSource = data.productList;

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });

  }
  addToCart(item) {
    this.cartItem = item;
    this._snackbar.open('Product added to cart successfully.', 'Ok', ConfigConstants.snackBarConfig);
  }
  getReiderProduct() {
   

    if (!this.cartItem) {
      this._snackbar.open('No cart item found please add product first..', 'Ok', ConfigConstants.snackBarConfig);
      return;
    }
    this._common.productID  = this.cartItem;
    this.router.navigate(['/benefit-product-rider/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
  }

  
  }

