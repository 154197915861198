import { Component, OnInit } from '@angular/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../_services/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from '../_services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-applicant-details',
  templateUrl: './applicant-details.component.html',
  styleUrls: ['./applicant-details.component.css'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class ApplicantDetailsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  customerInfoFormGroup: FormGroup;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  applicantId: string;
  isDisabled: boolean = false;
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private _common: CommonService,
    private router: Router,
    private _apiService: ApiService, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }

  //Set future dates disable to date picker
  maxDate = new Date();

  ngOnInit(): void {



    this.customerInfoFormGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.pattern]],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'), Validators.maxLength(120)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(14)]],
      aptno: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.maxLength(10)]
      ],
      aptno1: [''],
      street1: [''],
      city1: [''],
      state1: [''],
      country1: [''],
      zipcode1: ['', Validators.maxLength(10)],
      isApplicantReq: [''],
    });

    this.getApplicationDetailsByIs()
  }


  getApplicationDetailsByIs() {
    debugger
    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {
      debugger;


      this.blockUI.stop();
      if (data.applicant)
        this.getContactDetails(data.applicant)

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    });

  }
  //Get contact details if exist
  getContactDetails(applicantId) {
    debugger
    this.blockUI.start();
    this._apiService.getContactDetailsById(applicantId).subscribe((data: any) => {
      this.applicantId = applicantId;

      this.blockUI.stop();
      this.customerInfoFormGroup.get('firstName').setValue(data.firstName);
      this.customerInfoFormGroup.get('middleName').setValue(data.middleInitial);
      this.customerInfoFormGroup.get('lastName').setValue(data.lastName);
      this.customerInfoFormGroup.get('birthDate').setValue(data.birthDate);
      this.customerInfoFormGroup.get('gender').setValue(data.gender.toString());
      this.customerInfoFormGroup.get('email').setValue(data.email);
      this.customerInfoFormGroup.get('phoneNumber').setValue(data.mobileNumber);
      if (data.apartment != null) {
        this.customerInfoFormGroup.get('aptno').setValue(data.apartment);
      } else {
        this.customerInfoFormGroup.get('aptno').setValue('');
      }
      this.customerInfoFormGroup.get('street').setValue(data.street);
      this.customerInfoFormGroup.get('city').setValue(data.city);
      this.customerInfoFormGroup.get('state').setValue(data.state);
      this.customerInfoFormGroup.get('country').setValue(data.country);
      this.customerInfoFormGroup.get('zipcode').setValue(data.zipCode);
      //Secondry Address
      this.customerInfoFormGroup.get('aptno1').setValue(data.apartment1);
      this.customerInfoFormGroup.get('street1').setValue(data.street1);
      this.customerInfoFormGroup.get('city1').setValue(data.city1);
      this.customerInfoFormGroup.get('state1').setValue(data.state1);
      this.customerInfoFormGroup.get('country1').setValue(data.country1);
      this.customerInfoFormGroup.get('zipcode1').setValue(data.zipCode1);
      this.customerInfoFormGroup.get('isApplicantReq').setValue(this._common.isApplicantDifferent);

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }

  obj: any;

  updateApplicantDetails() {
    debugger
    if (this.customerInfoFormGroup.valid) {
      var data = this.customerInfoFormGroup.value;

      this.obj = new Object();

      if (this.applicantId)
        this.obj.contFamilyMemberId = this.applicantId;

      this.obj.contactId = this.contactId;
      this.obj.firstName = data.firstName;
      this.obj.middleName = data.middleName;
      this.obj.lastName = data.lastName;
      this.obj.birthDate = data.birthDate;
      this.obj.gender = parseInt(data.gender);
      this.obj.email = data.email;
      this.obj.phoneNumber = data.phoneNumber;
      this.obj.street = data.street;
      this.obj.city = data.city;
      this.obj.state = data.state;
      this.obj.country = data.country;
      this.obj.zipCode = data.zipcode;
      this.obj.aptmnt = data.aptno;
      //secondary address
      this.obj.street1 = data.street1;
      this.obj.city1 = data.city1;
      this.obj.state1 = data.state1;
      this.obj.country1 = data.country1;
      this.obj.zipCode1 = data.zipcode1;
      this.obj.aptmnt1 = data.aptno1;
      this.obj.isUpdate = true;

      var obj1 = new Object()
      obj1["contactId"] = this.contactId;
      obj1["applicationId"] = this.appId;

      obj1["members"] = new Array();

      obj1["members"].push(this.obj);

      var obj2 = new Object();
      obj2["contactMembers"] = obj1

      this.blockUI.start();

      this._apiService.upsertContactMembers(obj2).subscribe((data: any) =>
      {

        this.blockUI.stop();
        this._snackbar.open('Applicant details updated successfully.', 'Ok',ConfigConstants.snackBarConfig);

        var appObj = new Object();
        appObj["applicant"] = data;
        appObj["applicationId"] = this.appId;

        this._apiService.upsertApplication(appObj).subscribe((data: any) => {

          this.blockUI.stop();

          this.router.navigate(['./beneficiaries', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
          //this.router.navigate(['./tax-residancy', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
        }, err => {
          this.blockUI.stop();
          this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
        })
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      });
    }
  }
  ApplicantChk() {
    debugger
 
    if (this.customerInfoFormGroup.value.isApplicantReq == true) { this.isDisabled = false; }
    else {
      this.blockUI.start();
      this.isDisabled = true; 
      this._apiService.RemoveApplicant(this.appId, this.applicantId).subscribe((data: any) => {
        debugger;
        this.blockUI.stop();
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok');
      }); }
  
    

  }
}
