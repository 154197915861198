import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-otc',
  templateUrl: './otc.component.html',
  styleUrls: ['./otc.component.css']
})
export class OtcComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute) { }
  isFuneral: boolean = false;
  ngOnInit() {

    var queryParams: any
    this.activeRoute.queryParams.subscribe(params => {
      debugger;
      const id = params['id'];
      queryParams = {
        productId: params['productId'],
        productType: params['productType'],
        agentId: params['agentId'],
        contactId: '00000000-0000-0000-0000-000000000000',
        insuranceCompanyId: params['insuranceCompanyId'],
        partnerId: params['partnerId'],
        currencyId: params['currencyId']
      };
      console.log(queryParams);

      if (!queryParams) {
        this.isFuneral = true;
      }
      else {
        if (queryParams.productId.toUpperCase() == "8D7C6657-1E89-EE11-8179-002248993DB5") {
          this.isFuneral = true;
        }
        else {
          this.isFuneral = false;
        }
      }

    });

  }

}
