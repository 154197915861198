import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

class quesnModel {
  question: string;
  responseChoices: string[];
  id: string;
  sequence: number;
  responseType: string;
  newResponseValue: any;
  multiResponse: any; responseValue: any;
}

@Component({
  selector: 'app-questionnnaire',
  templateUrl: './questionnnaire.component.html',
  styleUrls: ['./questionnnaire.component.css']
})
export class QuestionnnaireComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private _apiService: ApiService, private route: ActivatedRoute, private cdr: ChangeDetectorRef,
    private router: Router, private _common: CommonService, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;

  appQuestions: any;
  myFormGroup: FormGroup;

  ngOnInit(): void {



    this.appQuestions = new Array();
    this.getApplicationQuestion();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  getApplicationQuestion() {
    debugger
    let group = {}

    this.blockUI.start();
    this._apiService.getQuestions(this.appId, this.productId).subscribe((data: any) => {
      this.blockUI.stop();
      debugger

      data.appQues.forEach(value => {
        value.newResponseValue = value.responseValue;
        if (value.responseType === "Date" && value.responseValue) {
          value.newResponseValue = new Date(value.responseValue);
        }
        else if (value.responseType === "Number" && value.responseValue) {
          value.newResponseValue = parseInt(value.responseValue);
        }
        else if (value.responseType === "Single Select" && value.responseChoices) {
          value.responseChoices = value.responseChoices.split("|");
        }
        else if (value.responseType === "Multi Select") {

          if (value.responseChoices)
            value.responseChoices = value.responseChoices.split("|");

          if (value.newResponseValue)
            value.newResponseValue = value.newResponseValue.split("|");

          value.multiResponse = [];

          if (value.newResponseValue) {
            value.newResponseValue.forEach(data => {

              if (data) {
                var obj = { name: data, isSelected: true };
                value.multiResponse.push(obj);

                var index = value.responseChoices.indexOf(data);
                if (index > -1) {
                  value.responseChoices.splice(index, 1);
                }
              }
            });

          }
          else {
            value.responseChoices.forEach(data => {
              var obj = { name: data, isSelected: false };
              value.multiResponse.push(obj);
            });
          }

          value = this.checkboxValidation(value);
        }
        //this.showStopperValueCheck(value, false);
        this.checkDependentQues(value, data.appQues, false);

        group[value.productQuestionId] = new FormControl('');
      })

      this.appQuestions = this.groupBy(data.appQues, function (item) {
        return [item.applicantId];
      });

      this.myFormGroup = new FormGroup(group);
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });
  }


  checkDependentQues(field, ques, flag) {
    var lstArr = new Array();

    var indexOfDependentQuestion = ques.findIndex(function (sub) {
      if (sub.dependentUponQuestionId && field.applicantId === sub.applicantId) {
        var index = sub.dependentUponQuestionId.indexOf(field.productQuestionId);

        if (index !== -1)
          lstArr.push(sub);
        //return index !== -1;
      }
    });

    if (lstArr.length > 0) {

      lstArr.forEach(val => {

        var _Queindex = ques.findIndex(v => v.productQuestionId === val.productQuestionId);

        if (field.newResponseValue !== null && (field.newResponseValue.trim() === ques[_Queindex].parentQueResponseValue || (ques[_Queindex].parentQueResponseValue.includes("|") && ques[_Queindex].parentQueResponseValue.includes(field.newResponseValue.trim())))) {
          ques[_Queindex].showDependentQuest = true;
          ques[_Queindex].isOptional = false;
        }
        else if (!ques[_Queindex].parentQueResponseValue)
          ques[_Queindex].showDependentQuest = true;
        else {
          ques[_Queindex].showDependentQuest = false;
          ques[_Queindex].newResponseValue = null;
          ques[_Queindex].isOptional = true;

          var _childQueindex = ques.findIndex(v => v.dependentUponQuestionId === val.productQuestionId);

          if (_childQueindex !== -1) {
            this.responseChildQuesHide(ques, _childQueindex);
          }
        }

      });

    }
  };

  responseChildQuesHide(ques, _childQueindex) {


    ques[_childQueindex].showDependentQuest = false;
    ques[_childQueindex].newResponseValue = null;
    ques[_childQueindex].isOptional = true;

    var _childQueindexN = ques.findIndex(v => v.dependentUponQuestionId === ques[_childQueindex].productQuestionId);

    if (_childQueindexN !== -1) {
      this.responseChildQuesHide(ques, _childQueindexN);
    }
  };

  checkboxValidation(field) {
    field.required = false;
    if (!field.isOptional) {
      field.keepGoing = true;
      field.multiResponse.forEach(value => {
        if (field.keepGoing) {
          if (value.isSelected) {
            field.required = false;
            field.keepGoing = false;
          }
          else
            field.required = true;
        }
      });
    }

    return field;
  };

  groupBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  sortBy(prop: string) {
    return this.appQuestions[0].sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  appData: any;
  submitResponses() {
    debugger;
    if (this.myFormGroup.valid) {

      this.appData = new Object();
      this.appData.appResponse = new Array();

      this.appQuestions[0].forEach(value => {

        if (value.responseType === "Date" && value.newResponseValue) {

          value.newResponseValue = new Date(value.newResponseValue);
          value.newResponseValue = ((value.newResponseValue.getMonth() + 1) < 10 ? '0' : '') + (value.newResponseValue.getMonth() + 1) + "/" + (value.newResponseValue.getDate() < 10 ? '0' : '') + value.newResponseValue.getDate() + "/" + value.newResponseValue.getFullYear();
        }
        else if (value.responseType === "Multi Select") {
          value.newResponseValue = null;

          value.multiResponse.forEach(data => {
            if (data.isSelected) {
              if (!value.newResponseValue)
                value.newResponseValue = data.name;
              else
                value.newResponseValue += "|" + data.name;
            }
          });
        }
        else if (!value.responseValue)
          value.responseValue = "";


        var obj = new Object();

        if (value.applicationResponseId)
          obj["responseId"] = value.applicationResponseId;

        obj["applicantId"] = value.applicantId;
        obj["applicationId"] = this.appId;
        obj["isShowStopper"] = false;
        obj["profileEntityName"] = "";
        obj["profileEntityFieldName"] = "";
        obj["responseValue"] = value.newResponseValue ? value.newResponseValue.toString() : "";
        obj["sequence"] = parseInt(value.sequence);
        obj["question"] = value.question;
        obj["productQuestionId"] = value.productQuestionId;

        this.appData.appResponse.push(obj);
      })

      if (this.appData.appResponse.length > 0) {
        this.blockUI.start();
        this._apiService.upsertApplicationResponse(this.appData).subscribe((data: any) => {
          this.blockUI.stop();
          this._snackbar.open('Responses updated successfully.', 'Ok', ConfigConstants.snackBarConfig);
          this.router.navigate(['./review', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
        }, err => {
          this.blockUI.stop();
          this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
        });
      }
    }
  }
  previous() {
    debugger
    if (this._common.showMiscallaneous) { this.router.navigate(['/miscellaneous/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }
    else { this.router.navigate(['/beneficiaries/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }

  }
}
