import { ProductRidersListViewModel } from "./../models/product-riders";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "./../_services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Label } from "ng2-charts";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { CashValuesOutput } from "../models/cash-value-output";
import { IllustrationDetail } from "../models/illustration-detail";
import { IllustrationInput } from "../models/illustration-request";
import { IllustrationOutput } from "../models/illustration-response";
import * as _ from "lodash";
import { CommonService } from "../_services/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { MatDrawer } from "@angular/material/sidenav";
import { ApplicationDetails } from "../client-review/models/application-details";
import { ConfigConstants } from "../constants/config-constants";

@Component({
  selector: "app-illustration-savings",
  templateUrl: "./illustration-savings.component.html",
  styleUrls: ["./illustration-savings.component.scss"],
})
export class IllustrationSavingsComponent implements OnInit {
  //***Route parameters***
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  //********************
  growthRate: number;
  term: number;
  contribAmount: number;
  sumCovered: number;
  maxTerm: number = 99;
  minTerm: number = 5;
  maxSumCovered: number = 100000;
  minSumCovered: number = 0;
  riders: ProductRidersListViewModel;

  // Form Controls
  illustrationForm = this.fb.group({
    // ***** Temporary fields ******
    // name: [null, Validators.required],
    // dateOfBirth: [null, Validators.required],
    // gender: [null, Validators.required],

    // *****************************
    growthRate: [null, Validators.required],
    term: [
      //TODO: Can change disabled term control based on type of plan (Eg: Term life = Enabled; Whole Life = Disabled)
      { value: null, disabled: false },
      [
        Validators.required,
        Validators.min(this.minTerm),
        Validators.max(this.maxTerm),
      ],
    ],
    contribAmount: [null, Validators.required],
    sumCovered: [
      null,
      [
        Validators.required,
        Validators.max(this.maxSumCovered),
        Validators.min(this.minSumCovered),
      ],
    ],
    currency: [null, Validators.required],
    isDeathBenefitInclusive: [null, Validators.required],
    contributionFrequency: [null, Validators.required],
  });

  cashValuesData: CashValuesOutput[];
  isIllustrationDataPresent: boolean;
  isCashValuesPresent: boolean;
  viewIllustrationDetail: boolean;

  // Illustration Table Columns
  displayedColumns: string[] = [
    "Year",
    "Age",
    "Accumulated Contributions",
    "Withdrawal Amounts",
    "Fund Value",
    "Cash Value",
    "Total Payable In Case Of Death",
  ];

  cashValuesDisplayedColumns: string[] = [
    "GrowthRate",
    "YearOrTerm1",
    "YearOrTerm2",
    "YearOrTerm3",
  ];
  // Illustration Chart Config
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[];
  lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Years",
          },
          ticks: {
            min: 0,
            stepSize: 5,
            callback(value: number, index, values) {
              return value + " Years";
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Cash Value",
          },
          ticks: {
            min: 0,
            stepSize: 1000000,
            callback(value: number, index, values) {
              return value / 1000000 + "M";
            },
          },
        },
      ],
    },
  };
  lineChartLegend = true;
  lineChartType = "line";

  access_token: string; //Temporary
  fileId: string;
  //productId: string;
  providerId: string;

  dataSource: MatTableDataSource<IllustrationDetail> = new MatTableDataSource<
    IllustrationDetail
  >();
  cashValuesDataSource: MatTableDataSource<
    CashValuesOutput
  > = new MatTableDataSource<CashValuesOutput>();
  cashValuesGrowthRates: string[];
  illustrationData: IllustrationOutput;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public _common: CommonService,
    private _snackbar: MatSnackBar
  ) {
    route.params.subscribe((data: any) => {
      debugger;
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      debugger;
      this._common.setActiveTab(data.id);
    });
    this.getApplicationDetailsById();
    this.apiService.authenticateGraphService().subscribe((data: any) => {
      this.access_token = data.access_token;
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("parametersSideNav") parametersSideNav: MatDrawer;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  @BlockUI() blockUI: NgBlockUI;
  applicationData: ApplicationDetails;
  getApplicationDetailsById() {
    this.blockUI.start();
    this.apiService.getApplicationById(this.appId).subscribe(
      (data: any) => {
        debugger;
        this.blockUI.stop();
        this.applicationData = data;
        //TODO: Add values to the filter form
      },
      (err) => {
        this.blockUI.stop();
        this._snackbar.open(
          "Something went wrong. Please contact administrator",
          "Ok",ConfigConstants.snackBarConfig
        );
      }
    );
  }

  onPageChanged() {
    this.dataSource = new MatTableDataSource<IllustrationDetail>(
      this.illustrationData.illustrationDetails
    );
    this.dataSource.paginator = this.paginator;
  }

  updateCashValues() {
    if (this.illustrationForm.invalid) {
      Object.keys(this.illustrationForm.controls).forEach((key) => {
        this.illustrationForm.get(key).markAsTouched();
      });
      return false;
    } else {
      // this.changeStep(2);
      this.blockUI.start();
      const IllustrationRequestBody: IllustrationInput = {
        illustrationInputParameters: {
          productId: 1,
          providerId: 1,

          name: "Ahmad Uzair",
          gender: "M",
          nationality: "Indian",
          dateOfBirth: "1962-05-15",
          termDuration: parseInt(this.illustrationForm.get("term").value),
          contributionAmount: parseInt(
            this.illustrationForm.get("contribAmount").value
          ),
          contributionFrequency: parseInt(
            this.illustrationForm.get("contributionFrequency").value
          ),
          growthRate: parseInt(this.illustrationForm.get("growthRate").value),
          currency: this.illustrationForm.get("currency").value,
          deathSumCovered: parseInt(
            this.illustrationForm.get("sumCovered").value
          ),
          isDeathBenefitApplicable: true,
          isDeathBenefitInclusive:
            this.illustrationForm.get("isDeathBenefitInclusive").value ==
            "true",
          riders: [],
        },
        illustrationFileInput: {
          applicationNumber: "WL-12345",
          fileId: this.fileId,
          productId: this.productId,
          providerId: this.providerId,
        },
      };
      // const IllustrationRequestBody: IllustrationRequest = {
      //   ApplicationNumber: 'WL-12345',
      //   Name: 'Ahmad Uzair',
      //   Nationality: 'Indian',
      //   Gender: 'M',
      //   DateOfBirth: '1960-12-23',
      //   ContributionAmount: 10000,
      //   ContributionFrequency: 12,
      //   GrowthRate: 5,
      //   Currency: 'AED',
      //   TermDuration:5
      // };

      this.apiService
        .getCashValues(IllustrationRequestBody, this.access_token, this.fileId)
        .subscribe(
          (cashValuesOutput: any) => {
            this.cashValuesData = cashValuesOutput.cashValues;

            //Get all Growth Rates and remove anyb empty strings
            let allGrowthRates = _.map(
              this.cashValuesData[0].cashValues,
              "growthRate"
            ).filter(Boolean);
            this.cashValuesGrowthRates = allGrowthRates;

            let cashValuesViewModel = [];
            allGrowthRates.forEach((growthRate) => {
              let growthRateModel = { growthRate: growthRate, values: [] };
              this.cashValuesData.forEach((element) => {
                growthRateModel.values = _.filter(element.cashValues, (n) => {
                  if (n.growthRate == growthRate) {
                    return n.value;
                  }
                });
              });
              cashValuesViewModel.push(growthRateModel);
            });

            //Assign the fileId which will now be used everytime a call to getIllustration is made
            this.fileId = cashValuesOutput.fileId;

            this.cashValuesDataSource = new MatTableDataSource<
              CashValuesOutput
            >(this.cashValuesData);

            this.blockUI.stop();
            this.isCashValuesPresent = true;
            this.isIllustrationDataPresent = false;
            this.parametersSideNav.close();
          },
          (error) => {
            if (error.status == 406) {
              this.dialog.open(InvalidParameterDialog);
              this.isIllustrationDataPresent = false;
            }
            this.blockUI.stop();
          }
        );
    }
  }

  /**
   * updateIllustration
   */
  public updateIllustration() {
    if (this.illustrationForm.invalid) {
      Object.keys(this.illustrationForm.controls).forEach((key) => {
        this.illustrationForm.get(key).markAsTouched();
      });
      return false;
    } else {
      // this.changeStep(2);
      this.blockUI.start();
      const IllustrationRequestBody: IllustrationInput = {
        illustrationInputParameters: {
          productId: 1,
          providerId: 1,

          name: "Ahmad Uzair",
          gender: "M",
          nationality: "Indian",
          dateOfBirth: "1962-05-15",
          termDuration: parseInt(this.illustrationForm.get("term").value),
          contributionAmount: parseInt(
            this.illustrationForm.get("contribAmount").value
          ),
          contributionFrequency: parseInt(
            this.illustrationForm.get("contributionFrequency").value
          ),
          growthRate: parseInt(this.illustrationForm.get("growthRate").value),
          currency: this.illustrationForm.get("currency").value,
          deathSumCovered: parseInt(
            this.illustrationForm.get("sumCovered").value
          ),
          isDeathBenefitApplicable: true,
          isDeathBenefitInclusive:
            this.illustrationForm.get("isDeathBenefitInclusive").value ==
            "true",
          riders: [],
        },
        illustrationFileInput: {
          applicationNumber: "WL-12345",
          fileId: this.fileId,
          productId: this.productId,
          providerId: this.providerId,
        },
      };
      // const IllustrationRequestBody: IllustrationRequest = {
      //   ApplicationNumber: 'WL-12345',
      //   Name: 'Ahmad Uzair',
      //   Nationality: 'Indian',
      //   Gender: 'M',
      //   DateOfBirth: '1960-12-23',
      //   ContributionAmount: 10000,
      //   ContributionFrequency: 12,
      //   GrowthRate: 5,
      //   Currency: 'AED',
      //   TermDuration:5
      // };

      this.apiService
        .getIllustration(
          IllustrationRequestBody,
          this.access_token,
          this.fileId
        )
        .subscribe(
          (illustrationOutput: IllustrationOutput) => {
            this.illustrationData = illustrationOutput;

            //Assign the fileId which will now be used everytime a call to getIllustration is made
            this.fileId = illustrationOutput.fileId;

            //Empty the array to remove any existing data
            this.lineChartData = [];

            //put only non-zero cash values
            this.illustrationData.illustrationDetails = _.filter(
              illustrationOutput.illustrationDetails,
              (n) => {
                if (n.cashValue > 0) return n;
              }
            );
            let cashValues = [];
            //Get all Cash Value Arrays
            let growthRatesCashValues = _.map(
              illustrationOutput.growthRateFluctuations,
              "cashValues"
            );

            //Put all cash value elements in a single array (Normalization)
            growthRatesCashValues.forEach((element) => {
              element.forEach((cashValue) => {
                cashValues.push(cashValue);
              });
            });

            //Assign x-axis labels
            //Had to hardcode these to make it equal to number of non-zero points in dataset
            this.lineChartLabels = [
              "5",
              "10",
              "15",
              "20",
              Math.max
                .apply(
                  Math,
                  this.illustrationData.growthRateFluctuations.map(function (
                    o
                  ) {
                    return o.year;
                  })
                )
                .toString(),
            ];
            _.map(this.illustrationData.growthRateFluctuations, "year");

            //Get Unique Growth Rates
            let uniqueGrowthRates = _.uniq(_.map(cashValues, "growthRate"));
            uniqueGrowthRates.forEach((element) => {
              let dataSet: ChartDataSets = {
                data: _.filter(
                  _.map(_.filter(cashValues, { growthRate: element }), "value"),
                  (n) => {
                    //Make sure to get only non-zero values
                    if (n > 0) {
                      return n;
                    }
                  }
                ),
                label: "Cash Value at " + element + "%",
              };
              this.lineChartData.push(dataSet);
            });

            this.dataSource = new MatTableDataSource<IllustrationDetail>(
              this.illustrationData.illustrationDetails
            );

            this.onPageChanged();

            this.blockUI.stop();
            //Display Illustration
            this.isIllustrationDataPresent = true;
            this.viewIllustrationDetail = false;
          },
          (error) => {
            if (error.status == 406) {
              this.dialog.open(InvalidParameterDialog);
              this.isIllustrationDataPresent = false;
            }
            this.blockUI.stop();
          }
        );
    }
  }

  updateApplication() {}
}

@Component({
  selector: "invalid-parameters-dialog",
  templateUrl: "invalid-parameters-dialog.html",
})
export class InvalidParameterDialog {}
