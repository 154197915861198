import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

const moment = _moment;

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class CustomerDetailComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  customerInfoFormGroup: FormGroup;

  //Set future dates disable to date picker
  maxDate = new Date();
  customerDetails: any;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  savingflag: boolean;

  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
      public _dialog: MatDialog, private _common: CommonService, private _snackbar: MatSnackBar
  ) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }

  ngOnInit(): void {
    this.savingflag = this._common.savingFlag;



    this.customerInfoFormGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.pattern]],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'), Validators.maxLength(120)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(14)]],
    });

    if (this.contactId != "undefined") { this.getContactDetails()}

  }

  //Get contact details if exist
  getContactDetails() {
    this.blockUI.start();
    this._apiService.getContactDetailsById(this.contactId).subscribe((data: any) => {
      this.blockUI.stop();
      this.customerInfoFormGroup.get('firstName').setValue(data.firstName);
      this.customerInfoFormGroup.get('middleName').setValue(data.middleInitial);
      this.customerInfoFormGroup.get('lastName').setValue(data.lastName);
      this.customerInfoFormGroup.get('birthDate').setValue(data.birthDate);
      this.customerInfoFormGroup.get('gender').setValue(data.gender.toString());
      this.customerInfoFormGroup.get('email').setValue(data.email);
      this.customerInfoFormGroup.get('phoneNumber').setValue(data.mobileNumber);

    }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }

  obj: any;
  //Upsert Contact details
  saveContactDetails() {
   
    this.customerDetails = new Object();

    if (this.customerInfoFormGroup.valid) {
      var customerData = this.customerInfoFormGroup.value;

      this.customerDetails.firstName = customerData.firstName;
      this.customerDetails.middleName = customerData.middleName;
      this.customerDetails.lastName = customerData.lastName;
      this.customerDetails.gender = customerData.gender;
      this.customerDetails.birthDate = customerData.birthDate;
      this.customerDetails.email = customerData.email;
      this.customerDetails.phoneNumber = customerData.phoneNumber;

      if (this.contactId && this.contactId != "undefined") {
        this.customerDetails.contactId = this.contactId;
      }
      this.blockUI.start();
      this._apiService.upsertContactDetails(this.customerDetails).subscribe((data: any) => {
        this.blockUI.stop();
        this.contactId = data;
        this.obj = new Object();

        if (this.appId && this.appId != "undefined")
          this.obj.applicationId = this.appId

        this.obj.agent = this.agentId
        this.obj.primaryMember = data
        this.obj.typeOfProduct = this.productType

        this.blockUI.start();
        this._apiService.upsertApplication(this.obj).subscribe((data: any) => {
          
          this.appId = data;
          this.blockUI.stop();
          //this.router.navigate(['./product-filters', this.contactId, this.productId, this.productType, this.agentId, data]);
          if (this._common.savingFlag) { this.router.navigate(['./illustration', this.contactId, this.productId, this.productType, this.agentId, data]); }
          else { this.router.navigate(['./product-filters', this.contactId, this.productId, this.productType, this.agentId, data]); }
        }, err => {
            this.blockUI.stop();
            this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
        });
      }, err => {
          this.blockUI.stop();
          this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      });
    }
  }
  previous() {
    debugger
    if (this.savingflag) { this.router.navigate(['/product-saving/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }
    else { this.router.navigate(['/product-type/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }

  }
  //saveandnext() {
  //  debugger
  //  if (this.savingflag) { this.router.navigate(['/benefits-saving/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }
  //  else { this.router.navigate(['/product-filters/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }

  //}
  //calculateAge(birthday) { // birthday is a date
  //  var ageDifMs = Date.now() - new Date(birthday).getTime();
  //  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  //  return Math.abs(ageDate.getUTCFullYear() - 1970);
 // }
}
