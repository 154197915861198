import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  active: any;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  savingFlag: boolean;

  constructor(private router: Router, private route: ActivatedRoute, public _common: CommonService) {
 //   debugger
    route.params.subscribe((data: any) => {

      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
   
  }

  ngOnInit() {
    this.savingFlag = this._common.savingFlag;
    if (this.savingFlag) {
      this.active = 11;
    }
    else { this.active = 0;}
  }
}
