import { ConfigConstants } from './../constants/config-constants';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.css']
})
export class AddressDetailsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private route: ActivatedRoute, private _formBuilder: FormBuilder,
    private _apiService: ApiService, private _common: CommonService,
    private router: Router, private _snackbar: MatSnackBar) {

    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.savingFlag = this._common.savingFlag;
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
    this.getAllCountry();

  }
  savingFlag: boolean;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  addressFormGroup: FormGroup;
  //showApplicantChk: boolean = true;
  allCountry: [];
  minDate = new Date();


  ngOnInit(): void {
    this.checkisApplicantDifferent();
    this.addressFormGroup = this._formBuilder.group({
      aptno: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.maxLength(10)]],
      aptno1: [''],
      street1: [''],
      city1: [''],
      state1: [''],
      country1: [''],
      zipcode1: ['', Validators.maxLength(10)],
    //  isApplicantReq: [''],
      nationality: ['', Validators.required],
      identityDocument: ['', Validators.required],
      idExpiry: ['', Validators.required],
      idNumber: ['', Validators.required],


    })

    this.getApplicationDetailsByIs()
  }

  obj: any;


  getApplicationDetailsByIs() {
    debugger
    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {

      debugger;
      this.blockUI.stop();
      if (data.applicant) {

        this.getContactDetails()
        this._common.isApplicantDifferent = data.isApplicantDifferent;
        // this.showApplicantChk = false;
        if (data.isApplicantDifferent == false) { this._common.hideApplicantTab(); } else { this._common.showApplicantTab(); }

      }

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });


  }

  ////Handle application is different checkbox
  //ApplicantChk() {
  //  debugger
  //  if (this.addressFormGroup.value.isApplicantReq == true) { this._common.showApplicantTab(); }
  //  else {
  //    this._common.hideApplicantTab();
  //    this.blockUI.start();
  //    this._apiService.RemoveApplicant(this.appId).subscribe((data: any) => {
  //      debugger;
  //      this.blockUI.stop();
  //    }, err => {
  //      this.blockUI.stop();
  //      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok');
  //    });
  //  }

  //}

  //All Getcountry
  getAllCountry() {
    debugger
    this.blockUI.start();
    this._apiService.getAllCountry().subscribe((data: any) => {

      debugger;
      this.blockUI.stop();
      this.allCountry = data;

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });


  }


  //Get contact details if exist
  getContactDetails() {
    debugger
    this.blockUI.start();
    this._apiService.getContactDetailsById(this.contactId).subscribe((data: any) => {
      debugger
      this.blockUI.stop();
      this.addressFormGroup.get('aptno').setValue(data.apartment);
      this.addressFormGroup.get('street').setValue(data.street);
      this.addressFormGroup.get('city').setValue(data.city);
      this.addressFormGroup.get('state').setValue(data.state);
      this.addressFormGroup.get('country').setValue(data.country);
      this.addressFormGroup.get('zipcode').setValue(data.zipCode);
      //secondary address
      this.addressFormGroup.get('aptno1').setValue(data.apartment1);
      this.addressFormGroup.get('street1').setValue(data.street1);
      this.addressFormGroup.get('city1').setValue(data.city1);
      this.addressFormGroup.get('state1').setValue(data.state1);
      this.addressFormGroup.get('country1').setValue(data.country1);
      this.addressFormGroup.get('zipcode1').setValue(data.zipCode1);
      this.addressFormGroup.get('nationality').setValue(data.nationality.id.toString());
      this.addressFormGroup.get('identityDocument').setValue(data.identityDocument.toString());
      this.addressFormGroup.get('idExpiry').setValue(data.idExpiry);
      this.addressFormGroup.get('idNumber').setValue(data.idNumber);
    //  this.addressFormGroup.get('isApplicantReq').setValue(this._common.isApplicantDifferent);


    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    })
  }

  updateAddressDetails() {
    debugger
    if (this.addressFormGroup.value) {
      var data = this.addressFormGroup.value;

      this.obj = new Object();

      this.obj.contactId = this.contactId;
      //primary address
      this.obj.apartment = data.aptno;
      this.obj.street = data.street;
      this.obj.city = data.city;
      this.obj.state = data.state;
      this.obj.country = data.country;
      this.obj.zipCode = data.zipcode;
      //secondary address
      this.obj.secondaryApartment = data.aptno1;
      this.obj.secondaryStreet = data.street1;
      this.obj.secondaryCity = data.city1;
      this.obj.secondaryState = data.state1;
      this.obj.secondaryCountry = data.country1;
      this.obj.secondaryZipCode = data.zipcode1;
      this.obj.nationality = data.nationality;
      this.obj.identityDocument = data.identityDocument;
      this.obj.idExpiry = data.idExpiry;
      this.obj.idNumber = data.idNumber;
      this.blockUI.start();

      this._apiService.upsertContactAddress(this.obj).subscribe((data: any) => {

        this.blockUI.stop();
        this._snackbar.open('Contact details updated successfully.', 'Ok', ConfigConstants.snackBarConfig);

        //if (this.addressFormGroup.value.isApplicantReq || !this.showApplicantChk)
        //  this.router.navigate(['./applicant', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
        //else
        //  this.router.navigate(['./beneficiaries', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
        //this.router.navigate(['./tax-residancy', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
        this.router.navigate(['./applicant', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
      });
    }
  }
  checkisApplicantDifferent() {
    if (this._common.isApplicantDifferent == false) { this._common.visible = false; }
    else { this._common.visible = true; }

  }

  previous() {
    debugger
    if (this._common.savingFlag) { this.router.navigate(['/applicationFund-saving/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }
    else { this.router.navigate(['/product-riders/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]); }


  }
}
