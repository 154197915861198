export class BenefitProduct {
  constructor() { }
 
  sumCovered: number;
  rateClass: string;
  policyTerm: number;
  frequency: string;
  currency: string;
  paymentmode: string;
    paymentTerm: number;
  applicationFee: number;
  premiumBaseAmount: number;
  sumCoveredRider: number;
  policyTermRider: number;
  sumCoveredRider1: number;
  policyTermRider1: number;
  sumCoveredRider2: number;
  policyTermRider2: number;
  sumCoveredRider3: number;
  policyTermRider3: number;
  premiumAmountRider: number;
  premiumAmountRider1: number;
  premiumAmountRider2: number;
  premiumAmountRider3: number;
 
}
