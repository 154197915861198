export class API_Constants {
  //public static readonly baseURL = "https://localhost:44308/";

  //PRODUCTION
  //public static readonly baseURL = "https://intelpeek-app.azurewebsites.net/";
  //public static readonly PaymentGatewayURL= "https://intelpeek-payments-app.azurewebsites.net";

  //UAT
  public static baseURL = "";
  public static PaymentGatewayURL = "";
  public static illustrationsUrl = "";

  // public static API_ENDPOINT_SEGMENT  =  'api/V1.0/';

  //DEV
  //public static readonly baseURL = "https://intelpeek-app-dev.azurewebsites.net/";
  //public static readonly PaymentGatewayURL= "https://intelpeek-payments-app-dev.azurewebsites.net";

  public static readonly GET_ApplicationById =
    "/Application/GetApplicationDetailsById/";
  //"api/V1.0/Application/QueryApplicationData/";
  public static readonly GET_Disclosures =
    "/Product/GetProductDisclouser/";
  public static readonly POST_UpdateApplicationStatusWithRemarks =
    "/Application/UpdateClientApproval/";
  public static readonly GET_VerifyOtp = "/Application/VerifyOTP/";
  public static readonly GET_SendOtp = "/Application/GenerateOTP/";
  public static readonly POST_CreatePaymentRecord = "/Payment/CreatePaymentForApplication";
  public static readonly POST_UpdatePaymentRecord = "/Payment/UpdatePaymentDetails";
  public static readonly GET_LatestIllustrationPDF = "/Application/GetLatestIllustrationDocForApplication/";
  public static readonly GET_ApplicationFormDoc = "/Application/GetApplicationFormDocument/";
  public static readonly GET_ApplicationFormExcel = "/Application/GenerateApplicationFormByExcel/";
  public static readonly GET_ApplicationDocuments = "/Application/GetDocument/";
  public static readonly GET_ApplicationDocumentsPostClientApproval = "/Document/GetApplicationDocumentsPostClientApproval/";
  public static readonly GET_DownloadDocument = "/Document/DownloadDocument/";
  public static readonly GET_AttachApplicationFormInApplication = "/Application/GenerateApplicationFormByExcelAndAttachDocument/";
  public static readonly POST_UpdateDocument = "/Document/UploadDocument/";
  

  //For illustrations
  public static readonly AUTH_ENDPOINT = '/api/Authentication/Authenticate';
  public static readonly DOWNLOAD_ILLUSTRATION = '/api/Graph/GetIllustrationPDF'
}
