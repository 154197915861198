import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { IllustrationOutput } from "../models/illustration-response";
import { Observable } from "rxjs";
import { API_Constants } from "../client-review/common/API_Constants";
import { IllustrationInput } from "../models/illustration-request";

@Injectable({
  providedIn: "root",
})
export class ApiService {

  //Local Configuration
  // API_URL = 'https://localhost:44308/api/V1.0';
  //AQP_WEB_URL = 'https://localhost:44308'

  //DEV Configuration
  //API_URL = 'https://intelpeek-app-dev.azurewebsites.net/api/V1.0';
  //AQP_WEB_URL = 'https://intelpeek-app-dev.azurewebsites.net';

  //UAT Configuration

  public AQP_WEB_URL = '';

  public API_URL = '';

  // public API_ENDPOINT_SEGMENT  =  'api/V1.0/';
  

  constructor(private http: HttpClient) { }


  //***********************************************CONTACT******************************************//
  //Get Contact Details by contact Id
  public getContactDetailsById(contactId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Contact/GetContactDetailsById/" + contactId
    );
  }
  //Upsert Contact details
  public upsertContactDetails(contactData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Contact/UpsertContactDetails",
      contactData
    );
  }
  //Upsert Contact Member
  public upsertContactMembers(contactData: any) {

    return this.http.post("https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertApplicant", contactData);

  }
  //Upsert Contact Address
  public upsertContactAddress(addressData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Contact/UpdateContactAddress",
      addressData
    );
  }
  //Get ContactTAx residancy
  public getTAxResidency(contactId: string, appId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetTaxResidency/" + contactId + "/" + appId
    );
  }

  //Upsert Contact Address
  public upsertTaxResidancy(taxData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Contact/UpsertTaxResidencyDetails",
      taxData
    );
  }

  //***********************************************APPLICATION******************************************//
  //Get Application Members by App Id
  public getApplicationMembers(applicationId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetapplicationFamilyMembers/" + applicationId
    );
  }

  //Get Application/product questions by App Id
  public getQuestions(applicationId: string, productId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetApplicationQuestions/" +
        applicationId +
        "/" +
        productId
    );
  }
  // Delete Appliction Member
  public DeleteApplicationMember(appMemberId: any) {
    return this.http.post("https://clientapp-dev.intelpeek.com/api/V1.0/Application/DeleteApplicationMember?appMemberId=" + appMemberId,null);
  }
  
  //Get Application by App Id
  public getApplicationById(applicationId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetApplicationDetailsById/" + applicationId
    );
  }

  //Get Application fund by App Id
  public getApplicationFundById(applicationId: string) {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetApplicationFunds/" + applicationId);
  }

  //Upsert Application fund
  public upsertApplicationFund(appData: any) {
    return this.http.post("https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertApplicationFunds", appData);
  }

  //Upsert Application
  public upsertApplication(appData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/CreateApplication",
      appData
    );
  }

  //Upsert Application Ressponse
  public upsertApplicationResponse(appData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertApplicationResponse",
      appData
    );
  }

  //Upsert Application Existing Insurance
  public upsertExistingInsurance(appData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertExistingInsurance",
      appData
    );
  }

  //Upsert Application Medical History
  public upsertMedicalHistory(appData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertExistingMedicalHistory",
      appData
    );
  }

  //Upsert Application Travel History
  public upsertTravelHistory(appData: any) {
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/UpsertExistingTravelHistory",
      appData
    );
  }

  //Remove applicant from application
  public RemoveApplicant(applicationId: any, applicantId:any) {
    debugger
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/RemoveApplicant?applicationId=" + applicationId + '&applicantId=' + applicantId, null
    );
  }

  //Get Existing Insurance by App Id
  public getAppExistingInsurance(applicationId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetExistingInsurance/" + applicationId
    );
  }

  //Get Medical History by App Id
  public getAppMedicalHistory(applicationId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetFamilyMedicalHistory/" + applicationId
    );
  }

  //Get Travel History by App Id
  public getAppTravelHistory(applicationId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetTravelHistory/" + applicationId
    );
  }

  //Get All Country
  public getAllCountry() {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetALLCountry");
  }

  public getPartnerDetailsById(partnerId:string){
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Master/GetPartnerDetailsById/" + partnerId);
  }

  public getInsuranceCompanyDetailsById(companyId:string){
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Master/GetInsuranceCompanyDetailsById/" + companyId);
  }

  

  //***********************************************PRODUCT******************************************//
  //Get Products
  public getProducts(queryString: string) {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProducts?" + queryString);
  }
  
  public getProductPremiumQuery(queryString: string) {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductPremium?" + queryString);

  }
  //Get Product Riders with filters
  public getProductRiders(queryString: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductRider?" + queryString
    );
  }

  //Get Product Riders by product id
  public getProductRidersByProductId(id: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductRiders/" + id
    );
  }
  //Get Product by product id
  public getProductByProductId(id: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductByProductId/" + id
    );
  }

  //Get Products by product type for agent
  public GetProductsByProductTypeForAgent(agentid: string, prodTypeid: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductsByProductTypeForAgent/" + agentid + "/" + prodTypeid
    );
  }

  //Get Product Properties
  public getProductProperties(productId: string) {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductProperty/" + productId);
  }

  //Get Product Funds
  public getProductFunds(productId: string) {
    return this.http.get("https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductFunds/" + productId);
  }


  //Get Product Disclosures
  public getProductDisclosures(productId: string, stateId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductDisclouser/" +
        productId +
        "/" +
        stateId
    );
  }

  //Get Partner Disclosures
  public getPartnerDisclosures(partnerId: string, stateId: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/GetPartnerDisclosures/" +
        partnerId +
        "/" +
        stateId
    );
  }

  //Get Get Product Riders Premium
  public getProductRidersPremium(queryString: string) {
    return this.http.get(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Product/GetProductRidersPremium??" + queryString
    
    );
  }
 
  //post Submit Application
  public SubmitApplicationByAgent(applicationId: any, agentId: any) {
    debugger
    return this.http.post(
      "https://clientapp-dev.intelpeek.com/api/V1.0/Application/SubmitApplicationByAgent?applicationId=" + applicationId + "&agentId=" + agentId, null
    );
  }

  //***************************************************ILLUSTRATION******************************************//

  //Get Illustration Chart + Table
  getIllustration(
    illustrationRequest: IllustrationInput,
    access_token: string,
    fileId: string
  ): Observable<IllustrationOutput> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      }),
    };
    let url =
      "https://intelpeek-illustrations-uat.azurewebsites.net/" +
      "api/Graph/GetIllustration"; //TODO: Add URL endpoint
    //API_Constants.API_BASE_URL + API_Constants.GET_ILLUSTRATION_ENDPOINT;
    // '?fileId=' +
    // fileId;
    let body = illustrationRequest;
    return this.http.post<IllustrationOutput>(url, body, httpOptions);
  }

  // Get illustration summary table
  getCashValues(
    illustrationRequest: IllustrationInput,
    access_token: string,
    fileId: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      }),
    };
    let url =
      "https://intelpeek-illustrations-uat.azurewebsites.net/" +
      "api/Graph/GetCashValues"; //TODO: Add URL endpoint //API_Constants.API_BASE_URL + API_Constants.GET_CASH_VALUES;
    // '?fileId=' +
    // fileId;
    let body = illustrationRequest;
    return this.http.post(url, body, httpOptions);
  }

  //Get access token for graph API
  authenticateGraphService() {
    let url =
      "https://intelpeek-illustrations-uat.azurewebsites.net/" +
      "api/Authentication/Authenticate"; //TODO: Add URL endpoint //API_Constants.API_BASE_URL + API_Constants.AUTH_ENDPOINT;
    return this.http.post(url, null);

}
}
