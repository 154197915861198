import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { CommonService } from '../_services/common.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatBottomSheetRef, MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns: string[] = ['company', 'plan', 'face_Amount', 'coverage', 'oneTimeFee', 'cost', 'action'];
  //dataSource1 = new MatTableDataSource(ELEMENT_DATA);

  //ngAfterViewInit() {
  //  this.dataSource1.sort = this.sort;
  //}

  constructor(private route: ActivatedRoute, private router: Router, private _apiService: ApiService, private _common: CommonService, public dialog: MatDialog, private _bottomSheet: MatBottomSheet, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }
  ngOnInit(): void {


    this.getApplicationDetailsById()
  }

  openCart(): void {
    const bottomSheetRef = this._bottomSheet.open(BottomSheetCart, {
      data: { item: this.cartItem },
    });
    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      if (Object.keys(dataFromChild).length === 0 && dataFromChild.constructor === Object) this.cartItem = null;
    });
  }

  cartItem: any;

  addToCart(item) {
    this.cartItem = item;
    this._snackbar.open('Product added to cart successfully.', 'Ok',ConfigConstants.snackBarConfig);
  }

  dataSource: any[];
  productProperties: any[];

  countries: any;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;

  obj: any;
  queryString: string;

  applicationDetails: any;

  getApplicationDetailsById() {

    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      this.applicationDetails = data;

      if (this.applicationDetails.benefitAmount >= this.applicationDetails.basicAppThreshold) {
        this._common.enableMiscallaneous();
      }
      else {
        this._common.disableMiscallaneous()
      }

      if (this.applicationDetails.product && this.applicationDetails.monthlyPremium != 0) {
        this.cartItem = new Object();

        this.cartItem.plan = this.applicationDetails.productLabel
        this.cartItem.cost = this.applicationDetails.monthlyPremium
        this.cartItem.oneTimeFee = this.applicationDetails.applicationFee
        this.cartItem.productId = this.applicationDetails.product
        this.cartItem.logoPath = this.applicationDetails.logoPath
      }

      this.getProductList();
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    });

  }

  //Get existing members
  getProductList() {

    this.obj = new Object();
    debugger
    //this.obj.effectiveDate = "2020-09-20";
    this.obj.benefitAmount = this.applicationDetails.benefitAmount;
    this.obj.rateClass = this.applicationDetails.rateClass;
    this.obj.productType = this.productType;
    this.obj.birthDate = this.applicationDetails.birthDate;
    this.obj.gender = this.applicationDetails.gender == 1 ? 610900000 : 610900001;
    this.obj.term = this.applicationDetails.term;
    this.obj.paymentFrequency = this.applicationDetails.paymentFrequency;
    this.obj.currency = this.applicationDetails.currency;

    this.queryString = this._common.objectToQueryString(this.obj);

    this.blockUI.start();
    this._apiService.getProducts(this.queryString).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      if (data && data.length > 0) {
        this.dataSource = data;
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }

  getProductProperties(productId) {
    this.blockUI.start();
    this._apiService.getProductProperties(productId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      if (data && data.prdPropertiesList.length > 0) {
        this.productProperties = data;

        const dialogRef = this.dialog.open(DialogProductProperties, {
          width: '950px', height: '450px',
          data: { data }
        });

        //dialogRef.afterClosed().subscribe(result => {
        //  console.log('The dialog was closed');
        //  this.animal = result;
        //});
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  obj1: any;

  updateApplication() {

    if (!this.cartItem) {
       this._snackbar.open('No cart item found please add product first..', 'Ok',ConfigConstants.snackBarConfig);
      return;
    }

    this.obj1 = new Object();

    this.obj1.applicationId = this.appId
    this.obj1.agent = this.agentId
    this.obj1.productId = this.cartItem.productId
    this.obj1.typeOfProduct = this.productType
    this.obj1.premium = parseFloat(this.cartItem.cost)
    this.obj1.oneTimeFee = parseFloat(this.cartItem.oneTimeFee)
    this.obj1.coverageType = 610900000;
    this.obj1.insuranceCompany = this.cartItem.company

    this.blockUI.start();
    this._apiService.upsertApplication(this.obj1).subscribe((data: any) => {

      this.blockUI.stop();
      this.router.navigate(['./product-riders', this.contactId, this.cartItem.productId, this.productType, this.agentId, this.appId]);
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    });
  }
}


export interface DialogData {
  properties: any[];
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'product-properties.component.html',
})
export class DialogProductProperties {

  @BlockUI() blockUI: NgBlockUI;
  proData: any;
  constructor(private _apiService: ApiService,
    public dialogRef: MatDialogRef<DialogProductProperties>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    debugger
    this.proData = data;
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  templateUrl: 'product-cart.component.html',
})
export class BottomSheetCart {
  itemDetails: any;
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetCart>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.itemDetails = data;
  }

  onRemoveItem() {
    this.itemDetails = {};
    this._bottomSheetRef.dismiss(this.itemDetails);
  }
}
