import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { CommonService } from '../_services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { newArray } from '@angular/compiler/src/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-product-riders',
  templateUrl: './product-riders.component.html',
  styleUrls: ['./product-riders.component.css']
})
export class ProductRidersComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;

  constructor(private _apiService: ApiService, private _commonService: CommonService,
    private _formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private _bottomSheet: MatBottomSheet, private _common: CommonService, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }

  ngOnInit(): void {



    this.productRidersFilterFormGroup = this._formBuilder.group({
      sumCovered: ['', Validators.required],
      rateClass: ['', Validators.required],
      term: ['', Validators.required],
      rider: ['', Validators.required],
    });

    this.getApplicationDetailsById();
  }

  dataSource: any[];

  queryString: string;
  productRidersFilterFormGroup: FormGroup;

  openCart(): void {
    const bottomSheetRef = this._bottomSheet.open(BottomSheetCartRider, {
      data: { item: this.cartItem },
    });
    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      if (Object.keys(dataFromChild).length === 0 && dataFromChild.constructor === Object) this.cartItem = null;
    });
  }

  cartItem: any;
  cartRiderItems: any[];

  addToCart(item, riderName) {

    var itemExists = this.cartItem.cartRiderItems.findIndex(p => p.riderId == item.riderId);

    if (itemExists >= 0) {
      this._snackbar.open('Product rider already exists in the cart.', 'Ok',ConfigConstants.snackBarConfig);
      return
    }
    else {
      item.riderName = riderName;
      this.cartItem.cartRiderItems.push(item);
      this._snackbar.open('Product rider added to cart successfully.', 'Ok',ConfigConstants.snackBarConfig);
    }
  }

  applicationDetails: any;

  getApplicationDetailsById() {

    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      this.applicationDetails = data;

      if (this.applicationDetails.product && this.applicationDetails.monthlyPremium != 0) {
        this.cartItem = new Object();
        this.productRidersFilterFormGroup.get("rateClass").setValue(data.rateClass.toString());
        this.cartItem.plan = this.applicationDetails.productLabel
        this.cartItem.cost = this.applicationDetails.monthlyPremium
        this.cartItem.oneTimeFee = this.applicationDetails.applicationFee
        this.cartItem.productId = this.applicationDetails.product
        this.cartItem.logoPath = this.applicationDetails.logoPath
        this.cartItem.cartRiderItems = new Array();

        if (this.applicationDetails.applicationRiders.length > 0 && this.applicationDetails.applicationRiders[0].riderPremium > 0) {
          this.applicationDetails.applicationRiders.forEach(data => {
            var obj = new Object();

            obj["riderName"] = data.name
            obj["riderAmount"] = data.riderBenefitAmount
            obj["riderPremium"] = data.riderPremium
            obj["appRiderId"] = data.appRiderId

            this.cartItem.cartRiderItems.push(obj);
          })
        }
      }

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    });
  }

  obj: any;

  //Get Product Riders
  getProductRiders() {
    debugger
    if (this.productRidersFilterFormGroup.valid) {
      var appData = this.productRidersFilterFormGroup.value;
      this.obj = new Object();

      this.obj.effectiveDate = new Date("2020-09-16").toISOString();
      this.obj.benefitAmount = appData.sumCovered;
      this.obj.productId = this.productId;
      this.obj.birthDate = this.applicationDetails.birthDate;
      this.obj.gender = this.applicationDetails.gender == 1 ? 610900000 : 610900001;
      this.obj.term = appData.term;
      this.obj.riderId = appData.rider
      this.obj.currencyId = this.applicationDetails.currency;
      this.obj.rateClassification = this.applicationDetails.rateClass;
      this.obj.paymentFrequency = this.applicationDetails.paymentFrequency;


      this.queryString = this._commonService.objectToQueryString(this.obj);

      this.blockUI.start();
      this._apiService.getProductRiders(this.queryString).subscribe((data: any) => {

        debugger
        this.blockUI.stop();
        this.dataSource = data;
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      })
    }
  }


  obj1: any;

  updateApplication() {

    if (this.cartItem.cartRiderItems.length > 0) {
      var appData = this.productRidersFilterFormGroup.value;
      this.obj1 = new Object();

      this.obj1.applicationId = this.appId
      this.obj1.agent = this.agentId
      this.obj1.appRider = new Array();

      this.cartItem.cartRiderItems.forEach(value => {

        var objc = new Object();

        if (value.appRiderId)
          objc["appRiderId"] = value.appRiderId;

        objc["riderBenefitAmount"] = parseFloat(appData.sumCovered);
        objc["riderPremium"] = parseFloat(value.riderPremium);
        objc["term"] = parseInt(appData.term);
        objc["riderId"] = value.riderId;

        this.obj1.appRider.push(objc);
      })

      this._apiService.upsertApplication(this.obj1).subscribe((data: any) => {

        this.router.navigate(['./address', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      });
    }
    else
      this.router.navigate(['./address', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
  }
}

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  templateUrl: 'product-riders-cart.component.html',
})
export class BottomSheetCartRider {
  itemDetails: any;
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetCartRider>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.itemDetails = data;
  }

  onRemoveItem(appRiderId) {

    var index = this.itemDetails.item.cartRiderItems.findIndex(p => p.appRiderId == appRiderId);

    if (index >= 0)
      this.itemDetails.item.cartRiderItems.splice(index, 1);

    this._bottomSheetRef.dismiss(this.itemDetails);
  }
}
