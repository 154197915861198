import { UpdateClientApproval } from "./../models/update-client-approval";
import { ProductDisclosure } from "./../models/product-disclosure";
import { API_Constants } from "./../common/API_Constants";
import { ApplicationDetails, GetApplicationDataforDocumentModel } from "./../models/application-details";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApplicationDocument } from "../models/application-document";
import { ApplicationDocumentList } from "../models/application-documents-list";

@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private http: HttpClient) { }

  getApplicationDetails(applicationId: string): Observable<GetApplicationDataforDocumentModel> {
    var url =
      API_Constants.baseURL + API_Constants.GET_ApplicationById + applicationId;
    // const applicationDetailsResponse: ApplicationDetails = {
    //   applicationId: 'd1eef2dd-c8fc-ea11-a813-000d3ab55801',
    //   agent: '88397279-34db-4fc5-9097-7f66b323f91c',
    //   agentName: 'Umang Vishwakarma',
    //   checker: null,
    //   checkerName: null,
    //   insurerRemark: null,
    //   insurerCompany: 'ae51a16f-d2db-ea11-a813-0022489d8bbc',
    //   insurerCompanyName: 'Takaful Emarat',
    //   statusReason: 610900001,
    //   productType: 'ea2c9fb5-e0db-ea11-a813-0022489d8bbc',
    //   productTypeLabel: 'Term Life',
    //   product: '8104ce8a-e2db-ea11-a813-0022489d8bbc',
    //   productLabel: 'Fixed Term',
    //   insuranceCompany: null,
    //   insuranceCompanyLabel: null,
    //   primaryMember: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //   primaryMemberName: 'Hamza Pervez',
    //   applicant: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //   applicantName: 'Hamza Pervez',
    //   currency: 'd009d7f5-bdd3-ea11-a813-000d3ab85fa8',
    //   currencyName: 'United Arab Emirates Dirham',
    //   paymentMode: null,
    //   paymentModeLabel: null,
    //   logoPath: '/assets/company-logo/takaful_emarat.png',
    //   term: 5,
    //   coverageType: 610900000,
    //   coverageTypeLabel: 'Primary',
    //   paymentFrequency: 610900000,
    //   paymentFrequencyLabel: 'Annual',
    //   effectiveDate: null,
    //   insuranceCompanyActionDate: null,
    //   expirationDate: null,
    //   createdOn: new Date('2020-09-22T11:43:50Z'),
    //   applicationNumber: 'IP-APP-20-000098',
    //   ipAddress: null,
    //   email: 'hamza@inboxkitten.com',
    //   phoneNumber: '(056) 486-9445',
    //   gender: 1,
    //   genderLabel: 'Male',
    //   apartment: null,
    //   street: 'GG Road',
    //   city: 'Bhopal',
    //   state: 'MP',
    //   county: 'India',
    //   zipCode: '52023hgvbjug',
    //   birthDate: new Date('1994-12-31T00:00:00'),
    //   rateClass: 610900000,
    //   rateClassLabel: 'Non-smoker',
    //   productTypeSequence: null,
    //   applicationFee: 135,
    //   basicAppThreshold: 500000,
    //   monthlyPremium: 75,
    //   benefitAmount: 100000,
    //   annualIncome: null,
    //   initialPayment: null,
    //   netPremium: null,
    //   quotedGrossPremium: null,
    //   applicationMembers: [
    //     {
    //       appFamilyMemberId: 'b5fbedb5-c9fc-ea11-a813-000d3ab1d47c',
    //       name: null,
    //       customer: {
    //         id: '5e6e65b1-c9fc-ea11-a813-000d3ab55801',
    //         name: 'Alia Wasl',
    //       },
    //       gaurdian: null,
    //       relationship: 610900000,
    //       relationshipLabel: 'Spouse',
    //       relationshiptogaurdian: null,
    //       relationshiptogaurdianLabel: null,
    //       role: 610900002,
    //       roleLabel: 'Beneficiary',
    //       percentage: 10,
    //     },
    //   ],
    //   applicationRiders: [
    //     {
    //       appRiderId: '3c5a5641-c9fc-ea11-a813-000d3ab1d47c',
    //       name: 'Critical Illness',
    //       riderPremium: 18.07,
    //       riderBenefitAmount: 20000,
    //       riderBenefitPercentage: null,
    //       term: 5,
    //       riderId: null,
    //     },
    //   ],
    //   applicationFunds: null,
    //   applicationResponses: [
    //     {
    //       applicationResponseid: '1b2f7f31-cafc-ea11-a813-000d3ab1d47c',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: '9d5ead1d-07f9-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'Enter a short note on your health condition',
    //       response: 'I am healthy',
    //     },
    //     {
    //       applicationResponseid: 'c7fa9f37-cafc-ea11-a813-000d3ab1d47c',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: 'a9328758-d2f5-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'How often do you smoke?',
    //       response: null,
    //     },
    //     {
    //       applicationResponseid: 'dffa9f37-cafc-ea11-a813-000d3ab1d47c',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: '8974f26b-d2f5-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'Height (centimeters)',
    //       response: '157',
    //     },
    //     {
    //       applicationResponseid: 'e5fa9f37-cafc-ea11-a813-000d3ab1d47c',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: 'e64680a9-5ff7-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'Weight (kgs)',
    //       response: '55',
    //     },
    //     {
    //       applicationResponseid: '4b794c30-cafc-ea11-a813-000d3ab55801',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: '0435c4a0-01f9-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question:
    //         'Do you consume or have ever consumed tobacco or alcohol in any form?',
    //       response: 'No',
    //     },
    //     {
    //       applicationResponseid: 'ba095336-cafc-ea11-a813-000d3ab55801',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: 'ee7ed5b2-01f9-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'How often do you drink?',
    //       response: 'Never',
    //     },
    //     {
    //       applicationResponseid: 'd1095336-cafc-ea11-a813-000d3ab55801',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: '3be07e11-07f9-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'Enter the date of tobacco free medical certificate',
    //       response: '09/14/2020',
    //     },
    //     {
    //       applicationResponseid: '240a5336-cafc-ea11-a813-000d3ab55801',
    //       name: null,
    //       applicantId: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //       applicant: 'Hamza Pervez',
    //       productQuestionId: '119d2784-27f8-ea11-a815-000d3ab1d47c',
    //       isknockout: false,
    //       profileEntityName: null,
    //       profileEntityFieldName: null,
    //       question: 'How many times do you smoke in a day?',
    //       response: '1',
    //     },
    //   ],
    //   travelHistory: [],
    //   medicalHistory: [],
    //   existingInsurance: [],
    //   taxResidency: [
    //     {
    //       taxResidencyId: '2e305585-c9fc-ea11-a813-000d3ab1d47c',
    //       taxId: '15635265632',
    //       customer: {
    //         id: '9bba33dd-c8fc-ea11-a813-000d3ab1d47c',
    //         name: 'Hamza Pervez',
    //       },
    //       country: {
    //         id: '3f88dd4f-6ee8-ea11-a817-000d3a666be7',
    //         name: 'United Arab Emirates',
    //       },
    //       reason: 'Active',
    //       name: 'Sample Tax Name',
    //     },
    //   ],
    //   hasFunds: true,
    //   hasIllustration: true,
    // };
    //return applicationDetailsResponse;
    return this.http.get<GetApplicationDataforDocumentModel>(url,{headers:{ 'Cache-Control': 'no-cache',
    Pragma: 'no-cache'}});
  }

  getLegalAgreement(disclosureRequest: ProductDisclosure) {
    const url = API_Constants.baseURL;
    return this.http.post(url, disclosureRequest, this.httpOptions);
  }

  updateApplicationStatus(
    updateApplicationApprovalRequest: UpdateClientApproval
  ) {
    const url = API_Constants.baseURL + API_Constants.POST_UpdateApplicationStatusWithRemarks;
    const body = {
      updatepplicationItems: updateApplicationApprovalRequest
    }
    return this.http.post(
      url,
      body,
      this.httpOptions
    );
  }

  getConfig() {
    return this.http.get('api/Config/GetClientConfig');
  }

  getIllustrationPDF(applicationId: string) {
    const url = API_Constants.baseURL + API_Constants.GET_LatestIllustrationPDF + applicationId;
    return this.http.get(url);
  }

  getApplicationForm(applicationId: string) {

    var action = API_Constants.GET_ApplicationFormExcel + applicationId + "/PDF";

    const url = API_Constants.baseURL + action;
    return this.http.get(url);
  }

  getAuthTokenForIllustration() {
    const url = API_Constants.illustrationsUrl + API_Constants.AUTH_ENDPOINT;
    return this.http.post(url, null);
  }

  downloadIllustration(fileId: string, productId: string, illustrationFileName: string, access_token: string) {
    const httpOptionsWithAuth = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + access_token,
      }),
      responseType: 'blob' as 'json'
    };

    let url = API_Constants.illustrationsUrl + API_Constants.DOWNLOAD_ILLUSTRATION + '?productId=' + productId + '&fileId=' + fileId + '&illustrationFileName=' + illustrationFileName;
    return this.http.get(url, httpOptionsWithAuth);
  }

  getApplicationDocumentsByApplicationId(applicationId: string, appClientApprovalDate:string): Observable<ApplicationDocumentList> {
    let url ="";
    if(appClientApprovalDate)
      url = API_Constants.baseURL + API_Constants.GET_ApplicationDocumentsPostClientApproval + applicationId; // + '/' + 'APPLICATION';
    else
      url = API_Constants.baseURL + API_Constants.GET_ApplicationDocuments + applicationId + '/' + 'APPLICATION'; 
    return this.http.get<ApplicationDocumentList>(url);
  }

  downloadDocumentByDocumentId(documentId: string) {
    const url = API_Constants.baseURL + API_Constants.GET_DownloadDocument + documentId;
    return this.http.get(url);
  }


  downloadPdf(base64String, fileName) {
    const fileType = this.getMimeTypeAndExtension(base64String);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // download PDF in IE
      let byteChar = atob(base64String);
      let byteArray = new Array(byteChar.length);
      for (let i = 0; i < byteChar.length; i++) {
        byteArray[i] = byteChar.charCodeAt(i);
      }
      let uIntArray = new Uint8Array(byteArray);
      let blob = new Blob([uIntArray], { type: fileType.mimeType });
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}${fileType.extension}`);
    } else {
      // Download PDF in Chrome etc.
      const source = `data:${fileType.mimeType};base64,${base64String}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}${fileType.extension}`
      link.click();
    }
  }


  getMimeTypeAndExtension(base64String: string) {
    let mimeTypeAndExtension = {
      mimeType: '',
      extension: ''
    }
    switch (base64String.charAt(0)) {
      case '/':
        mimeTypeAndExtension.mimeType = 'image/jpg'
        mimeTypeAndExtension.extension = '.jpg'
        break;
      case 'i':
        mimeTypeAndExtension.mimeType = 'image/png';
        mimeTypeAndExtension.extension = '.png';
        break;
      case 'R':
        mimeTypeAndExtension.mimeType = 'image/gif';
        mimeTypeAndExtension.extension = '.gif';
        break;
      case 'J':
        mimeTypeAndExtension.mimeType = 'application/pdf';
        mimeTypeAndExtension.extension = '.pdf';
        break;
      default:
        mimeTypeAndExtension.mimeType = 'image/jpg'
        mimeTypeAndExtension.extension = '.jpg'
        break;
    }
    return mimeTypeAndExtension;
  }

  attachApplicationFormInApplicationRecord(applicationId: string, docId:string | null, isApprovedByClient:boolean | null) {
    const url = API_Constants.baseURL + API_Constants.GET_AttachApplicationFormInApplication + applicationId + '/' + 'PDF'+ '/' +docId + '/' + isApprovedByClient;
    return this.http.get(url);
  }

  updateApplicationDocument(document : ApplicationDocument,){
    const url = API_Constants.baseURL + API_Constants.POST_UpdateDocument
    return this.http.post(url,document);
  }
}
