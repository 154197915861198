import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApplicationService } from '../client-review/services/application.service';
import { API_Constants } from '../client-review/common/API_Constants';

@Component({
  selector: 'app-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss']
})
export class DownloadDocumentComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  //Query params for download application form
  documentType: string;
  documentId: string;
  applicationId: string;
  downloadType: string;

  //Query params for download illustration
  productId: string;
  fileId: string;
  illustrationFileName: string;
  access_token: string;

  base64File: string = null;

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.parent.queryParams.subscribe((params) => {

      this.downloadType = params.downloadType;
      this.documentType = params.documentType;

      //For Download application form
      this.documentId = params.documentId;
      this.applicationId = params.applicationId;


      //For Download illustration
      this.illustrationFileName = params.illustrationFileName;
      this.productId = params.productId;
      this.fileId = params.fileId;

      this.applicationService.getConfig().subscribe((data: any) => {
        API_Constants.baseURL = data.value.baseUrl;
        API_Constants.PaymentGatewayURL = data.value.paymentGatewayUrl;
        API_Constants.illustrationsUrl = data.value.illustrationsUrl;
        if (this.documentType == "ApplicationForm") {
          this.blockUI.start('Downloading Application Form...')
          this.applicationService.getApplicationForm(this.applicationId).subscribe((data: any) => {
            this.blockUI.stop();
            if (data) {
              if (this.downloadType == "embed") {
                this.base64File = data;
              } else {
                this.downloadPdf(data, this.applicationId + '-AppForm');
              }
            }

          }, (error) => {
            this.blockUI.stop();
          });
        }
        else if (this.documentType == "Illustration") {
          this.blockUI.start('Downloading Illustration...');
          this.applicationService.getAuthTokenForIllustration().subscribe((data: any) => {
            this.access_token = data.access_token;
            this.applicationService.downloadIllustration(this.fileId, this.productId, this.illustrationFileName, this.access_token).subscribe((blob: any) => {
              this.blockUI.stop();
              if (blob) {
                var file = new Blob([blob], { type: 'application/pdf' });
                //trick to download store a file having its URL
                var fileURL = URL.createObjectURL(file);
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = this.illustrationFileName ? this.illustrationFileName.split('.')[0] + '.pdf' : "illustration.pdf";
                document.body.appendChild(a); //create the link "a"
                a.click(); //click the link "a"
                document.body.removeChild(a); //remove the link "a"
              }

            }, (error) => {
              this.blockUI.stop();
            });
          });

        }
      });


    });
  }

  downloadPdf(base64String, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // download PDF in IE
      let byteChar = atob(base64String);
      let byteArray = new Array(byteChar.length);
      for (let i = 0; i < byteChar.length; i++) {
        byteArray[i] = byteChar.charCodeAt(i);
      }
      let uIntArray = new Uint8Array(byteArray);
      let blob = new Blob([uIntArray], { type: 'application/pdf' });
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    } else {
      // Download PDF in Chrome etc.
      const source = `data:application/pdf;base64,${base64String}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.pdf`
      link.click();
    }
  }

}
