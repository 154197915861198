import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.css']
})

export class BeneficiariesComponent implements OnInit {
  displayedColumns: string[] = ['name', 'relationshipLabel', 'birthDate', 'percentage','action'];
  @BlockUI() blockUI: NgBlockUI;
  events: string[] = [];
  public age: any;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  beneficiaryFormGroup: FormGroup;
  rows: FormArray;
  guardianRequired: boolean;
  applicationId: string;
  dataSource: any[];
  showHiderow = false;
  public percentage = 0;
  //Set future dates disable to date picker
  maxDate = new Date();
 
  constructor(private _formBuilder: FormBuilder, private _snackbar: MatSnackBar, private _apiService: ApiService, private route: ActivatedRoute, public _common: CommonService, private router: Router) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }

  ngOnInit(): void {


    this.rows = this._formBuilder.array([]);

    this.beneficiaryFormGroup = this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      relationship: [''],
      birthDate: [''],
      guardianfirstName: [''],
      guardianlastName: [''],
      guardianrelationship: [''],
      guardianbirthDate: [''],
      percentage: [''],
      id: ['']
    });

    this.beneficiaryFormGroup.addControl('rows', this.rows);

    this.applicationId = this.appId;

    this.getExistingMembers();
  }

  //Get existing members
  getExistingMembers() {
    debugger
    this.percentage = 0;
    this.blockUI.start();
   
    this._apiService.getApplicationMembers(this.applicationId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      if (data && data.appFamilyMembers.length > 0) {
        data.appFamilyMembers.forEach((i) => {
          debugger
          this.percentage = this.percentage + i.percentage;
        });
        this.dataSource = data.appFamilyMembers.filter(p => p.role == 610900002);
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    })
  }

  percentageValidation() {

    var totalPercentage = this.beneficiaryFormGroup.value.rows.reduce((accum, item) => accum + item.percentage, 0);

    if (totalPercentage > 100) {
      this._snackbar.open('Percentage should not be greater than 100', 'Ok', ConfigConstants.snackBarConfig);
      return;
    }
  }

  showGuardian() {
    debugger
   // var containsChild = this.beneficiaryFormGroup.value.rows.filter(p => p.relationship == "610900001");
    //if beneficiary age less than 18 year required guardian details
    if (this.age<18) {
      this.guardianRequired = true;
      this.beneficiaryFormGroup.get('guardianfirstName').setValidators(Validators.required)
      this.beneficiaryFormGroup.get('guardianlastName').setValidators(Validators.required)
      this.beneficiaryFormGroup.get('guardianrelationship').setValidators(Validators.required)
      this.beneficiaryFormGroup.get('guardianbirthDate').setValidators(Validators.required)
    }
    else {
      this.guardianRequired = false;
      this.beneficiaryFormGroup.get('guardianfirstName').setValue(null);
      this.beneficiaryFormGroup.get('guardianlastName').setValue(null);
      this.beneficiaryFormGroup.get('guardianrelationship').setValue(null);
      this.beneficiaryFormGroup.get('guardianbirthDate').setValue(null);
      this.beneficiaryFormGroup.get('guardianfirstName').clearValidators();
      this.beneficiaryFormGroup.get('guardianlastName').clearValidators();
      this.beneficiaryFormGroup.get('guardianrelationship').clearValidators();
      this.beneficiaryFormGroup.get('guardianbirthDate').clearValidators();
    }

    this.beneficiaryFormGroup.get('guardianfirstName').updateValueAndValidity();
    this.beneficiaryFormGroup.get('guardianlastName').updateValueAndValidity();
    this.beneficiaryFormGroup.get('guardianrelationship').updateValueAndValidity();
    this.beneficiaryFormGroup.get('guardianbirthDate').updateValueAndValidity();
  }

  onAddRow() {
    //this.rows.status == "VALID"
    this.percentageValidation();

    if (this.rows&& !this.showHiderow) {
      this.rows.push(this.createItemFormGroup());
      this.showHiderow = true;
      this.guardianRequired = false;
    }
  }



  createItemFormGroup(): FormGroup {
    return this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      relationship: ['', Validators.required],
      percentage: ['', Validators.required],
      birthDate: ['', Validators.required],
      guardianfirstName: ['', Validators.required],
      guardianlastName: ['', Validators.required],
      guardianrelationship: ['', Validators.required],
      guardianbirthDate: ['', Validators.required],
      id: ['']
    });
  }
 
  //delete record
  deleteRow(appFamilyMemberId:any) {
    debugger
   this.blockUI.start();
    this._apiService.DeleteApplicationMember(appFamilyMemberId).subscribe((data: any) => {
      this.blockUI.stop(); this._snackbar.open('Delete Application Member.', 'Ok', ConfigConstants.snackBarConfig);
      this.getExistingMembers() 
      this.showHiderow = false;
    

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    })
 
    
  }
  //Romove row
  onRemoveRow(rowIndex: number) {
    debugger
    this.rows.removeAt(rowIndex);
    this.showHiderow = false;
  }
  //add record
  onEditRow(item) {
    debugger
    this.percentageValidation();
    //   && this.rows.status == "VALID"
    if (this.rows) {
      this.showHiderow = true;
      this.guardianRequired = true;
      this.rows.push(this.updateItemFormGroup(item));

    }
  }
  updateItemFormGroup(item): FormGroup {
    debugger
    var name = item.name;
    var nameSplit = name.split(" ");
    if (item.gaurdian != null) {
  var gaurdianname = item.gaurdian.name;
      var gaurdiannameSplit = gaurdianname.split(" ");
      return this._formBuilder.group({
        firstName: [nameSplit[0], Validators.required],
        lastName: [nameSplit[1], Validators.required],
        birthDate: [item.birthDate, Validators.required],
        relationship: [item.relationship.toString(), Validators.required],

        guardianfirstName: [gaurdiannameSplit[0], Validators.required],
        guardianlastName: [gaurdiannameSplit[1], Validators.required],
        guardianrelationship: [item.relationshipWithGaurdian.toString(), Validators.required],
        guardianbirthDate: [item.guardianbirthDate, Validators.required],
        percentage: [item.percentage, Validators.required],
        id: [item.appFamilyMemberId]
      });
    }
    else {
      this.showGuardian();
      return this._formBuilder.group({
        firstName: [nameSplit[0], Validators.required],
        lastName: [nameSplit[1], Validators.required],
        birthDate: [item.birthDate, Validators.required],
        relationship: [item.relationship.toString(), Validators.required],
        guardianfirstName: ['', Validators.required],
        guardianlastName: ['', Validators.required],
        guardianrelationship: ['', Validators.required],
        guardianbirthDate: ['', Validators.required],
 percentage: [item.percentage, Validators.required],
        id: [item.appFamilyMemberId]
      });}
  }

  beneficiaryData: any;
  //Save tax residancy
  saveBeneficiaries() {
    debugger
    //&& this.beneficiaryFormGroup.controls["rows"].valid
    if (this.beneficiaryFormGroup.controls["rows"].value ) {
      this.beneficiaryData = new Object();

      this.beneficiaryData.applicationId = this.appId;

      var beneficiaryDataLst = this.beneficiaryFormGroup.controls["rows"].value;
      this.beneficiaryData.familyMembers = new Array();

      if (beneficiaryDataLst.length > 0) {
        for (var i = 0; i < beneficiaryDataLst.length; i++) {

          var obj = new Object();

          if (beneficiaryDataLst[i].id)
            obj["familyMemberId"] = beneficiaryDataLst[i].id;

          obj["firstName"] = beneficiaryDataLst[i].firstName;
          obj["primaryMemberId"] = this.contactId;
          obj["birthDate"] = beneficiaryDataLst[i].birthDate;
          obj["role"] = 610900002;
          obj["lastName"] = beneficiaryDataLst[i].lastName;
          obj["relationshipToPrimary"] = parseInt(beneficiaryDataLst[i].relationship);
          obj["percentage"] = parseFloat(beneficiaryDataLst[i].percentage);
          if (beneficiaryDataLst[i].guardianfirstName != null && beneficiaryDataLst[i].guardianfirstName !="") {
          obj["gaurdianFirstName"] = beneficiaryDataLst[i].guardianfirstName;
            obj["gaurdianLastName"] = beneficiaryDataLst[i].guardianlastName;
            obj["guardianbirthDate"] = beneficiaryDataLst[i].guardianbirthDate;
            obj["guardianRelationship"] = parseInt(beneficiaryDataLst[i].guardianrelationship);}
      
        
          this.beneficiaryData.familyMembers.push(obj);
        }
      }
      this.blockUI.start();
      
      this._apiService.upsertApplication(this.beneficiaryData).subscribe((data: any) => {
        this.blockUI.stop(); this._snackbar.open('Benefeciary details added/updated successfully.', 'Ok', ConfigConstants.snackBarConfig);
        this.getExistingMembers();
        this.showHiderow = false;
        this.rows.removeAt(0);
        this._snackbar.open('Beneficiary record added/updated successfully.', 'Ok', ConfigConstants.snackBarConfig);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
      })
    }
  }
  checkMiscellaneousPercentageDone() {
    debugger

    if (this.percentage == 100) {
      this.router.navigate(['/miscellaneous/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
    }
    else { this._snackbar.open('Sum of Percentage share across all the beneficiaries must be equal to 100', 'Ok', ConfigConstants.snackBarConfig); }
  }
  checkQuestionnairePercentageDone() {
    debugger
    if (this.percentage == 100) {

      this.router.navigate(['/questionnaire/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
    }
    else { this._snackbar.open('Sum of Percentage share across all the beneficiaries must be equal to 100', 'Ok', ConfigConstants.snackBarConfig); }


  }
 
  checkAge(type: string, event: MatDatepickerInputEvent<Date>) {
   var date;
    this.events.push(`${event.value}`);
    this.events.forEach((e) => {
      date = e
    })
   var today = new Date();
    var birthDate = new Date(date);
  this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age = this.age - 1;
      if (this.age < 0) {
        //this._snackbar.open('age is less than 18 year', 'Ok', ConfigConstants.snackBarConfig);
        

      }
      else { //this._snackbar.open('age is grather than 18 year', 'Ok', ConfigConstants.snackBarConfig);
      }
    }
    else {
    }
    this.showGuardian();
  }
  guardiancheckAge(type: string, event: MatDatepickerInputEvent<Date>) {
    
    var date;
    this.events.push(`${event.value}`);
    this.events.forEach((e) => {
      date = e
    })
    var today = new Date();
    var birthDate = new Date(date);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age = this.age - 1;
      if (this.age < 0) {
        this._snackbar.open('Guardian age cannot be less than 18 year', 'Ok', ConfigConstants.snackBarConfig);


      }
      else {// this._snackbar.open('age is grather than 18 year', 'Ok', ConfigConstants.snackBarConfig);
      }
    }
    else {
      if (this.age < 18) {

        this._snackbar.open('Guardian age cannot be less than 18 year', 'Ok', ConfigConstants.snackBarConfig);

      }
    }
   
  }

  previous() {
    debugger
    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      this._common.isApplicantDifferent = data.isApplicantDifferent;
 this._common.visible = true;
        this.router.navigate(['/applicant/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
     }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });
 }
}
