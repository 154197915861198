import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router} from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-application-fund',
  templateUrl: './application-fund.component.html',
  styleUrls: ['./application-fund.component.css']
})
export class ApplicationFundComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private _formBuilder: FormBuilder, private _apiService: ApiService, private route: ActivatedRoute, private _common: CommonService, private router: Router, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  applicationFundFormGroup: FormGroup;
  rows: FormArray;
  dataSource: any[];
  funds: any[];
  showHideRow = false;
  public percentage = 0;

  ngOnInit(): void {

    this.rows = this._formBuilder.array([]);

    this.applicationFundFormGroup = this._formBuilder.group({
      fund: [''],
      allocation: ['']
    });

    this.applicationFundFormGroup.addControl('rows', this.rows);

    this.getProductFunds()
    this.getApplicationFund()
  }
  onAddRow() {

    if (this.rows && this.rows.status == "VALID" && !this.showHideRow) {
      this.rows.push(this.createItemFormGroup());
      this.showHideRow = true;
    }
  }
  onEditRow(item) {

    if (this.rows && this.rows.status == "VALID" && !this.showHideRow) {
      this.rows.push(this.updateItemFormGroup(item));
      this.showHideRow = true;
    }
  }
  updateItemFormGroup(item): FormGroup {
    return this._formBuilder.group({
      fund: [item.fund.id, Validators.required],
      allocation: [item.allocationPercent, Validators.required],
      id: [item.id],
    });
  }

  onRemoveRow(rowIndex: number) {
    this.rows.removeAt(rowIndex);
    this.showHideRow = false;
  }

  createItemFormGroup(): FormGroup {
    return this._formBuilder.group({

      fund: ['', Validators.required],
      allocation: ['', Validators.required],
      id: ['']
    });
  }

  //Get Countries
  getProductFunds() {
    this.blockUI.start();
    this._apiService.getProductFunds(this.productId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      this.funds = data.prdFundsList;
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }


  //Get existing application fund
  getApplicationFund() {
    this.percentage = 0;
    this.blockUI.start();
    this._apiService.getApplicationFundById(this.appId).subscribe((data: any) => {
      debugger;
      this.blockUI.stop();
      if (data && data.appFundsList.length > 0) {
        data.appFundsList.forEach((i) => {
          debugger
          this.percentage = this.percentage + i.allocationPercent;
        });
        this.dataSource = data.appFundsList;
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  fundData: any;
  //travelHistoryData: any;
  //Save application fund
  saveApplicationFund() {
    debugger
    if (this.applicationFundFormGroup.controls["rows"].value && this.applicationFundFormGroup.controls["rows"].valid) {
      this.fundData = new Object();

      var fundDataLst = this.applicationFundFormGroup.controls["rows"].value;
      this.fundData.applicationFund = new Array();

      if (fundDataLst.length > 0) {
        for (var i = 0; i < fundDataLst.length; i++) {

          var obj = new Object();

          if (fundDataLst[i].id)
            obj["appfundRecordId"] = fundDataLst[i].id;

          obj["applicationId"] = this.appId;
          obj["fund"] = fundDataLst[i].fund;
          obj["allocation"] = fundDataLst[i].allocation;

          this.fundData.applicationFund.push(obj);
        }
      }
      this.blockUI.start();
      this._apiService.upsertApplicationFund(this.fundData).subscribe((data: any) => {
        this.blockUI.stop();
        this.getApplicationFund()
        this.showHideRow = false;
        this.rows.removeAt(0);
        this._snackbar.open('Application Fund record added/updated successfully.', 'Ok',ConfigConstants.snackBarConfig);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      })
    }
  }

  percentageValidation() {
    debugger
    var totalPercentage = this.applicationFundFormGroup.value.rows.reduce((accum, item) => accum + item.allocation, 0);

    if (totalPercentage > 100) {
      this._snackbar.open('Allocation Percentage should not be greater than 100', 'OK',ConfigConstants.snackBarConfig);
      return;
    }
  }
  previous() {
    debugger

    this.router.navigate(['/illustration-saving/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
  }
  checkAllocationPercentage() {
    debugger

    if (this.percentage == 100) {
      this.router.navigate(['/address/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
    }
    else { this._snackbar.open('Sum of Allocation Percentage across all the application funds must be equal to 100', 'Ok',ConfigConstants.snackBarConfig); }
  }

}
