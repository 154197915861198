import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrls: ['./product-filters.component.css']
})
export class ProductFiltersComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  productFilterFormGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    public _dialog: MatDialog, private _common: CommonService, private _snackbar: MatSnackBar) {

    route.params.subscribe((data: any) => {
     
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });

  }

  ngOnInit(): void {


    this.productFilterFormGroup = this._formBuilder.group({
      sumCovered: ['', Validators.required],
      rateClass: ['', Validators.required],
      term: ['', Validators.required],
      frequency: ['', Validators.required],
      currency: ['d009d7f5-bdd3-ea11-a813-000d3ab85fa8', Validators.required],
      paymentmode: ['', Validators.required],
    });

    this.getApplicationDetailsByIs()
  }

  obj: any;

  updateApplication() {
    debugger
    if (this.productFilterFormGroup.valid) {
      var appData = this.productFilterFormGroup.value;

      this.obj = new Object();

      this.obj.applicationId = this.appId
      this.obj.agent = this.agentId
      this.obj.benefitAmount = appData.sumCovered
      this.obj.rateClass = parseInt(appData.rateClass)
      this.obj.term = parseInt(appData.term)
      this.obj.paymentFrequency = parseInt(appData.frequency)
      this.obj.paymentMode = parseInt(appData.paymentmode)
      this.obj.currencyId = appData.currency

      this.blockUI.start();
      this._apiService.upsertApplication(this.obj).subscribe((data: any) => {

        this.blockUI.stop();
        this.router.navigate(['./products', this.contactId, this.productId, this.productType, this.agentId, this.appId]);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      });
    }
  }

  getApplicationDetailsByIs() {
    debugger
    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {

      debugger;
      this.blockUI.stop();
      this.productFilterFormGroup.get('sumCovered').setValue(data.benefitAmount);
      this.productFilterFormGroup.get('rateClass').setValue(data.rateClass.toString());
      this.productFilterFormGroup.get('term').setValue(data.term.toString());
      this.productFilterFormGroup.get('frequency').setValue(data.paymentFrequency.toString());
      this.productFilterFormGroup.get('currency').setValue(data.currency);
      this.productFilterFormGroup.get('paymentmode').setValue(data.paymentMode.toString());
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    });

  }
}
