import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.css']
})
export class MiscellaneousComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private _apiService: ApiService, private _common: CommonService, private _snackbar: MatSnackBar) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });

  }
  dataSourceExistingInsurance: any[];
  dataSourceTravelHistory: any[];
  dataSourceMedicalHistory: any[];

  countries: any;
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  membersInfoFormGroup: FormGroup;
  existingInsuranceFormGroup: FormGroup;
  medicalHistoryFormGroup: FormGroup;
  travelHistoryFormGroup: FormGroup;
  rows: FormArray;
  rows1: FormArray;
  rows2: FormArray;
  rows3: FormArray;

  ngOnInit(): void {

    this.rows = this._formBuilder.array([]);
    this.rows1 = this._formBuilder.array([]);
    this.rows2 = this._formBuilder.array([]);
    this.rows3 = this._formBuilder.array([]);

    this.membersInfoFormGroup = this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      dob: [''],
      gender: ['']
    });
    this.existingInsuranceFormGroup = this._formBuilder.group({
      companyName: [''],
      planType: [''],
      issueDt: [''],
      sumCovered: [''],
      status: [''],
      id: ['']
    });
    this.medicalHistoryFormGroup = this._formBuilder.group({
      name: [''],
      age: [''],
      diagnosedAge: [''],
      healthState: [''],
      ageAtDeath: [''],
      causeOfDeath: [''],
      id: ['']
    });
    this.travelHistoryFormGroup = this._formBuilder.group({
      name: [''],
      country: [''],
      noOfVisit: [''],
      daysVisit: [''],
      reason: [''],
      id: ['']
    });

    this.membersInfoFormGroup.addControl('rows', this.rows);
    this.existingInsuranceFormGroup.addControl('rows1', this.rows1);
    this.medicalHistoryFormGroup.addControl('rows2', this.rows2);
    this.travelHistoryFormGroup.addControl('rows3', this.rows3);

    this.getCountries();
    this.getExistingInsurance()
    this.getExistingMedicalHistory()
    this.getExistingTravelHistory()
  }

  existingInsuranceShow = false;
  medicalHistoryShow = false;
  travelHistoryShow = false;

  onAddRow(flag) {
    debugger
    if (flag == 1) {
      if (this.rows && this.rows.status == "VALID") {
        this.rows.push(this.createItemFormGroup(flag));
      }
    }
    if (flag == 2) {
      if (this.rows1 && this.rows1.status == "VALID") {
        this.rows1.push(this.createItemFormGroup(flag));
        this.existingInsuranceShow = true
      }
    }
    if (flag == 3) {
      if (this.rows2 && this.rows2.status == "VALID") {
        this.rows2.push(this.createItemFormGroup(flag));
        this.medicalHistoryShow = true
      }
    }
    if (flag == 4) {
      if (this.rows3 && this.rows3.status == "VALID") {
        this.rows3.push(this.createItemFormGroup(flag));
        this.travelHistoryShow = true
      }
    }
  }


  onRemoveRow(rowIndex: number, flag) {
    if (flag == 1) {
      this.rows.removeAt(rowIndex);
    }
    if (flag == 2) {
      this.rows1.removeAt(rowIndex);
      this.existingInsuranceShow = false
    }
    if (flag == 3) {
      this.rows2.removeAt(rowIndex);
      this.medicalHistoryShow = false
    }
    if (flag == 4) {
      this.rows3.removeAt(rowIndex);
      this.travelHistoryShow = false
    }
  }

  createItemFormGroup(flag): FormGroup {
    if (flag == 1) {
      return this._formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dob: ['', Validators.required],
        gender: ['', Validators.required]
      });
    }
    if (flag == 2) {
      return this._formBuilder.group({
        companyName: ['', Validators.required],
        planType: ['', Validators.required],
        issueDt: ['', Validators.required],
        sumCovered: ['', Validators.required],
        status: ['', Validators.required],
        id: ['']
      });
    }
    if (flag == 3) {
      return this._formBuilder.group({
        name: ['', Validators.required],
        age: [''],
        diagnosedAge: ['', Validators.required],
        healthState: ['', Validators.required],
        ageAtDeath: [''],
        causeOfDeath: [''],
        id: ['']
      });
    }
    if (flag == 4) {
      return this._formBuilder.group({
        name: ['', Validators.required],
        country: ['', Validators.required],
        noOfVisit: ['', Validators.required],
        daysVisit: ['', Validators.required],
        reason: ['', Validators.required],
        id: ['']
      });
    }
  }

  onEditRow(flag, item) {
    debugger
    if (flag == 2) {
      if (this.rows1 && this.rows1.status == "VALID" && !this.existingInsuranceShow) {
        this.rows1.push(this.updateItemFormGroup(flag, item));
        this.existingInsuranceShow = true
      }
    }
    if (flag == 3) {
      if (this.rows2 && this.rows2.status == "VALID" && !this.medicalHistoryShow) {
        this.rows2.push(this.updateItemFormGroup(flag, item));
        this.medicalHistoryShow = true
      }
    }
    if (flag == 4) {
      if (this.rows3 && this.rows3.status == "VALID" && !this.travelHistoryShow) {
        this.rows3.push(this.updateItemFormGroup(flag, item));
        this.travelHistoryShow = true
      }
    }
  }

  updateItemFormGroup(flag, item): FormGroup {
    if (flag == 2) {
      return this._formBuilder.group({
        companyName: [item.companyName, Validators.required],
        planType: [item.planType, Validators.required],
        issueDt: [item.yearOfIssue, Validators.required],
        sumCovered: [item.sumCovered, Validators.required],
        status: [item.insuranceStatus, Validators.required],
        id: [item.id]
      });
    }
    if (flag == 3) {
      return this._formBuilder.group({
        name: [item.name, Validators.required],
        age: [item.age],
        diagnosedAge: [item.diagnosedAge, Validators.required],
        healthState: [item.healthState, Validators.required],
        ageAtDeath: [item.ageOfDeath],
        causeOfDeath: [item.CauseOfDeath, Validators.required],
        id: [item.id]
      });
    }
    if (flag == 4) {
      return this._formBuilder.group({
        name: [item.name, Validators.required],
        country: [item.country.id, Validators.required],
        noOfVisit: [item.numberOfVisit, Validators.required],
        daysVisit: [item.numberOfDaysVisit, Validators.required],
        reason: [item.reason, Validators.required],
        id: [item.id]
      });
    }
  }
  //Get Countries
  getCountries() {
    this.blockUI.start();
    this._apiService.getAllCountry().subscribe((data: any) => {
      this.blockUI.stop();
      debugger;
      this.countries = data;
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  //Get existing members
  getExistingInsurance() {
    this.blockUI.start();
    this._apiService.getAppExistingInsurance(this.appId).subscribe((data: any) => {
      this.blockUI.stop();
      debugger;
      if (data && data.getExistingInsuranceList.length > 0) {
        this.dataSourceExistingInsurance = data.getExistingInsuranceList;
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  //Get existing members
  getExistingTravelHistory() {
    this.blockUI.start();
    this._apiService.getAppTravelHistory(this.appId).subscribe((data: any) => {
      this.blockUI.stop();
      debugger;
      if (data && data.getTravelHistoryList.length > 0) {
        this.dataSourceTravelHistory = data.getTravelHistoryList;
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  //Get existing members
  getExistingMedicalHistory() {
    this.blockUI.start();
    this._apiService.getAppMedicalHistory(this.appId).subscribe((data: any) => {
      this.blockUI.stop();
      debugger;
      if (data && data.getFamilyMedicalHistoryList.length > 0) {
        this.dataSourceMedicalHistory = data.getFamilyMedicalHistoryList;
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
    })
  }
  existingInsuranceData: any;
  //Save tax residancy
  saveExistingInsurance() {
    debugger
    if (this.existingInsuranceFormGroup.controls["rows1"].value && this.existingInsuranceFormGroup.controls["rows1"].valid) {
      this.existingInsuranceData = new Object();

      var existingInsuranceDataLst = this.existingInsuranceFormGroup.controls["rows1"].value;
      this.existingInsuranceData.existingInsuranceItems = new Array();

      if (existingInsuranceDataLst.length > 0) {
        for (var i = 0; i < existingInsuranceDataLst.length; i++) {

          var obj = new Object();

          if (existingInsuranceDataLst[i].id)
            obj["solzis_existinginsuranceid"] = existingInsuranceDataLst[i].id;

          obj["applicationid"] = this.appId;
          obj["companyname"] = existingInsuranceDataLst[i].companyName;
          obj["plantype"] = existingInsuranceDataLst[i].planType;
          obj["yearofissue"] = existingInsuranceDataLst[i].issueDt;
          obj["sumcovered"] = existingInsuranceDataLst[i].sumCovered.toString();
          obj["insurancestatus"] = existingInsuranceDataLst[i].status;

          this.existingInsuranceData.existingInsuranceItems.push(obj);
        }
      }
      this.blockUI.start();
      this._apiService.upsertExistingInsurance(this.existingInsuranceData).subscribe((data: any) => {
        this.blockUI.stop();
        this.getExistingInsurance()
        this.existingInsuranceShow = false;
        this.rows1.removeAt(0);
        this._snackbar.open('Existing Insurance record added/updated successfully.', 'Ok',ConfigConstants.snackBarConfig);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      })
    }
  }
  travelHistoryData: any;
  //Save tax residancy
  savetravelHistories() {
    debugger
    if (this.travelHistoryFormGroup.controls["rows3"].value && this.travelHistoryFormGroup.controls["rows3"].valid) {
      this.travelHistoryData = new Object();

      var travelHistoryDataLst = this.travelHistoryFormGroup.controls["rows3"].value;
      this.travelHistoryData.travelhistoryItems = new Array();

      if (travelHistoryDataLst.length > 0) {
        for (var i = 0; i < travelHistoryDataLst.length; i++) {

          var obj = new Object();

          if (travelHistoryDataLst[i].id)
            obj["travelHistoryId"] = travelHistoryDataLst[i].id;

          obj["applicationid"] = this.appId;
          obj["member"] = this.contactId;
          obj["name"] = travelHistoryDataLst[i].name;
          obj["country"] = travelHistoryDataLst[i].country;
          obj["numberofvisits"] = parseInt(travelHistoryDataLst[i].noOfVisit);
          obj["numberofdaysvisit"] = parseInt(travelHistoryDataLst[i].daysVisit);
          obj["reason"] = travelHistoryDataLst[i].reason;

          this.travelHistoryData.travelhistoryItems.push(obj);
        }
      }
      this.blockUI.start();
      this._apiService.upsertTravelHistory(this.travelHistoryData).subscribe((data: any) => {
        this.blockUI.stop();
        this.getExistingTravelHistory()
        this.travelHistoryShow = false;
        this.rows3.removeAt(0);
        this._snackbar.open('Travel history record added/updated successfully.', 'Ok',ConfigConstants.snackBarConfig);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      })
    }
  }
  medicalHistoryData: any;
  //Save tax residancy
  saveMedicalHistories() {
    debugger
    if (this.medicalHistoryFormGroup.controls["rows2"].value && this.medicalHistoryFormGroup.controls["rows2"].valid) {
      this.medicalHistoryData = new Object();

      var medicalHistoryDataLst = this.medicalHistoryFormGroup.controls["rows2"].value;
      this.medicalHistoryData.medicalhistoryItems = new Array();

      if (medicalHistoryDataLst.length > 0) {
        for (var i = 0; i < medicalHistoryDataLst.length; i++) {

          var obj = new Object();

          if (medicalHistoryDataLst[i].id)
            obj["familymedicalhistoryid"] = medicalHistoryDataLst[i].id;

          obj["relationship"] = null;
          obj["applicationid"] = this.appId;
          obj["name"] = medicalHistoryDataLst[i].name;
          obj["diagnosedage"] = medicalHistoryDataLst[i].diagnosedAge;
          obj["age"] = parseInt(medicalHistoryDataLst[i].age);
          obj["healthstate"] = medicalHistoryDataLst[i].healthState;
          obj["ageatdeath"] = parseInt(medicalHistoryDataLst[i].ageAtDeath) >= 0 ? parseInt(medicalHistoryDataLst[i].ageAtDeath) : null;
          obj["causeofdeath"] = medicalHistoryDataLst[i].causeOfDeath ? medicalHistoryDataLst[i].causeOfDeath : "";

          this.medicalHistoryData.medicalhistoryItems.push(obj);
        }
      }
      this.blockUI.start();
      this._apiService.upsertMedicalHistory(this.medicalHistoryData).subscribe((data: any) => {
        this.blockUI.stop();
        this.getExistingMedicalHistory()
        this.medicalHistoryShow = false;
        this.rows2.removeAt(0);
        this._snackbar.open('Medical history record added/updated successfully.', 'Ok',ConfigConstants.snackBarConfig);
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok',ConfigConstants.snackBarConfig);
      })
    }
  }
}
