import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../_services/common.service';

@Component({
  selector: 'app-product-saving',
  templateUrl: './product-saving.component.html',
  styleUrls: ['./product-saving.component.css']
})
export class ProductSavingComponent implements OnInit {
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  constructor(private route: ActivatedRoute, private router: Router, private _common: CommonService) {
    route.params.subscribe((data: any) => {
      debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
      debugger
      this._common.setActiveTab(data.id);
    });
  }

  ngOnInit(): void {
   
  }
  previous() {
    debugger

    this.router.navigate(['/product-type/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
  }
  saveandnext() {
    debugger

    this.router.navigate(['/customer-detail/' + this.contactId + '/' + this.productId + '/' + this.productType + '/' + this.agentId + '/' + this.appId]);
  }
}
