import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';


@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.css']
})
export class ProductReviewComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router, public _common: CommonService, private _snackbar: MatSnackBar) {

    route.params.subscribe((data: any) => {
      //debugger
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
     // debugger
      this._common.setActiveTab(data.id);
    });
  }

  applicationData: any;
  ngOnInit(): void {


    this.getApplicationDetailsById()
  }

  getApplicationDetailsById() {

    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {

      //debugger;
      this.blockUI.stop();
      this.applicationData = data;


      if (this.applicationData.benefitAmount >= this.applicationData.basicAppThreshold) {
        this._common.enableMiscallaneous();
      }
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });

  }

  Submit() {
    debugger
    this.blockUI.start();
    this._apiService.SubmitApplicationByAgent(this.appId, this.agentId).subscribe((data: any) => {

      debugger;
      
      this.blockUI.stop();
      this._snackbar.open('Successfully submit application', 'Ok', ConfigConstants.snackBarConfig);

    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok');
    });
  }
}
