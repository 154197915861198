import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../_services/common.service';


@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css']
})
export class MainViewComponent implements OnInit {
  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  termLifeId = "ea2c9fb5-e0db-ea11-a813-0022489d8bbc";
  savingId = "ee5fe3f8-e0db-ea11-a813-0022489d8bbc";
  constructor(private route: ActivatedRoute, private router: Router, private _common: CommonService ) {
    route.params.subscribe((data: any) => {
     
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
  }

  ngOnInit(): void {

  }
  getID(id: any)
  {
    debugger
    //term life
    if (id == this.termLifeId) {
      this.router.navigate(['/customer-detail/' + this.contactId + '/' + this.productId + '/' + this.termLifeId + '/' + this.agentId + '/' + this.appId]);
      this._common.savingFlag = false;

    }
    //saving
    else if (id == this.savingId) {
      this.router.navigate(['/product-saving/' + this.contactId + '/' + this.productId + '/' + this.savingId + '/' + this.agentId + '/' + this.appId]);
      this._common.savingFlag = true;
    }
   
  }
}
