import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';
import { FlexLayoutModule } from '@angular/flex-layout';
/*import { ImageCropperComponent, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';*/
import { ImageCropperModule } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

// Material Modules
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from "@angular/material/toolbar";  

// Components
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './_layout/app-header/app-header.component';
import { BlankLayoutComponent } from './_layout/blank-layout/blank-layout.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { MainViewComponent } from './main-view/main-view.component';
import { LoginPageComponent } from './otc/login-page/login-page.component';
import { AgentProductComponent } from './agent-product/agent-product.component';
import { BenefitProductRiderComponent } from './benefit-product-rider/benefit-product-rider.component';
import { ClientReviewComponent } from './client-review/client-review.component';
import { DownloadDocumentComponent } from './download-document/download-document.component';
import { OtcComponent } from './otc/otc.component';
import { MatMomentDateModule } from "@angular/material-moment-adapter";

// Routes
//const routes: Routes = [
//  {
//    path: '',
//    component: BlankLayoutComponent,
//    children: [
//      { path: 'product-type/:contactId/:productId/:productType/:agentId/:appId', component: MainViewComponent },
//      { path: 'product/:contactId/:productId/:productType/:agentId/:appId', component: AgentProductComponent },
//      { path: 'benefit-product-rider/:contactId/:productId/:productType/:agentId/:appId', component: BenefitProductRiderComponent }
//    ]
//  },
//  { path: '', redirectTo: '/login', pathMatch: 'full' },
//  { path: 'login', component: LoginPageComponent },
//  { path: 'otc', loadChildren: () => import('./otc/otc.module').then(m => m.OtcModule) },
//  { path: 'client-review', loadChildren: () => import('./client-review/client-review.module').then(m => m.ClientReviewModule) },
//  { path: 'download-document', loadChildren: () => import('./download-document/download-document.module').then(m => m.DownloadDocumentModule) }
//];

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'otc', loadChildren: () => import('./otc/otc.module').then(m => m.OtcModule) },
  { path: 'client-review', loadChildren: () => import('./client-review/client-review.module').then(m => m.ClientReviewModule) },
  { path: 'download-document', loadChildren: () => import('./download-document/download-document.module').then(m => m.DownloadDocumentModule) },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: 'product-type/:contactId/:productId/:productType/:agentId/:appId', component: MainViewComponent },
      { path: 'product/:contactId/:productId/:productType/:agentId/:appId', component: AgentProductComponent },
      { path: 'benefit-product-rider/:contactId/:productId/:productType/:agentId/:appId', component: BenefitProductRiderComponent },
      // Additional child routes...
    ]
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },  
];

import {
  ProductsComponent,
  DialogProductProperties,
  BottomSheetCart,
} from "./products/products.component";
import { CustomerDetailComponent } from "./customer-detail/customer-detail.component";
import { ProductFiltersComponent } from "./product-filters/product-filters.component";
import {
  ProductRidersComponent,
  BottomSheetCartRider,
} from "./product-riders/product-riders.component";
import { AddressDetailsComponent } from "./address-details/address-details.component";
import { ApplicantDetailsComponent } from "./applicant-details/applicant-details.component";
import { TaxResidencyComponent } from "./tax-residency/tax-residency.component";
import { BeneficiariesComponent } from "./beneficiaries/beneficiaries.component";
import { MiscellaneousComponent } from "./miscellaneous/miscellaneous.component";
import { QuestionnnaireComponent } from "./questionnnaire/questionnnaire.component";
import { ProductReviewComponent } from "./product-review/product-review.component";
import { BenefitsSavingsComponent } from "./benefits-savings/benefits-savings.component";
import {
  IllustrationSavingsComponent,
  InvalidParameterDialog,
} from "./illustration-savings/illustration-savings.component";
import { ChartsModule } from "ng2-charts";
import { MessageDialogComponent } from "./client-review/summary/summary.component";
import { ProductSavingComponent } from "./product-saving/product-saving.component";
import { BenefitsSavingComponent } from "./benefits-saving/benefits-saving.component";
import { ApplicationFundComponent } from "./application-fund/application-fund.component";


@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    BlankLayoutComponent,
    AppLayoutComponent,
    MainViewComponent,
    LoginPageComponent,
    AgentProductComponent,
    BenefitProductRiderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BlockUIModule.forRoot(),
    FlexLayoutModule,
    ImageCropperModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatToolbarModule,
    RouterModule.forRoot([
      {
        path: "",
        component: BlankLayoutComponent,
        children: [
          {
            path:
              "product-type/:contactId/:productId/:productType/:agentId/:appId",
            component: MainViewComponent,
            data: { id: 0, isVisible: false, title: "" },
          },
        ],
      },
      { path: 'product/:contactId/:productId/:productType/:agentId/:appId', component: AgentProductComponent },
      { path: 'benefit-product-rider/:contactId/:productId/:productType/:agentId/:appId', component: BenefitProductRiderComponent },
      {
        path: "",
        component: AppLayoutComponent,
        children: [

          { path: 'customer-detail/:contactId/:productId/:productType/:agentId/:appId', component: CustomerDetailComponent, data: { id: 0, isVisible: false, title: "" } },
          { path: 'product-filters/:contactId/:productId/:productType/:agentId/:appId', component: ProductFiltersComponent, data: { id: 1, isVisible: false, title: "" } },
          { path: 'products/:contactId/:productId/:productType/:agentId/:appId', component: ProductsComponent, data: { id: 2, isVisible: false, title: "" } },
          { path: 'product-riders/:contactId/:productId/:productType/:agentId/:appId', component: ProductRidersComponent, data: { id: 3, isVisible: false, title: "" } },
          { path: 'address/:contactId/:productId/:productType/:agentId/:appId', component: AddressDetailsComponent, data: { id: 4, isVisible: false, title: "" } },
          { path: 'applicant/:contactId/:productId/:productType/:agentId/:appId', component: ApplicantDetailsComponent, data: { id: 5, isVisible: false, title: "" } },
          //{ path: 'tax-residancy/:contactId/:productId/:productType/:agentId/:appId', component: TaxResidencyComponent, data: { id: 6, isVisible: false, title: "" } },
          { path: 'beneficiaries/:contactId/:productId/:productType/:agentId/:appId', component: BeneficiariesComponent, data: { id: 7, isVisible: false, title: "" } },
          { path: 'miscellaneous/:contactId/:productId/:productType/:agentId/:appId', component: MiscellaneousComponent, data: { id: 8, isVisible: false, title: "" } },
          { path: 'questionnaire/:contactId/:productId/:productType/:agentId/:appId', component: QuestionnnaireComponent, data: { id: 9, isVisible: false, title: "" } },
          {
            path: 'review/:contactId/:productId/:productType/:agentId/:appId', component: ProductReviewComponent, data: { id: 10, isVisible: false, title: "" }
          },
          //saving
          { path: 'product-saving/:contactId/:productId/:productType/:agentId/:appId', component: ProductSavingComponent, data: { id: 11, isVisible: false, title: "" } },
          //{ path: 'benefits-saving/:contactId/:productId/:productType/:agentId/:appId', component: BenefitsSavingComponent, data: { id: 12, isVisible: false, title: "" } },
          { path: 'illustration-saving/:contactId/:productId/:productType/:agentId/:appId', component: IllustrationSavingsComponent, data: { id: 13, isVisible: false, title: "" } },
          { path: 'applicationFund-saving/:contactId/:productId/:productType/:agentId/:appId', component: ApplicationFundComponent, data: { id: 14, isVisible: false, title: "" } },



        ]
      },
      { path: "client-review", component: ClientReviewComponent, loadChildren: "./client-review/client-review.module#ClientReviewModule", },
      { path: "otc", component:OtcComponent, loadChildren:"./otc/otc.module#OtcModule"},
      {
        path: 'otc',
        loadChildren: () => import('./otc/otc.module').then(m => m.OtcModule)
      },

      { path: "download-document", component: DownloadDocumentComponent, loadChildren: "./download-document/download-document.module#DownloadDocumentModule" },
      { path: 'login', component: LoginPageComponent },
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
