import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  showMiscallaneous: boolean;
  visible: boolean;
  active: number;
  savingFlag: boolean;
  isApplicantDifferent: boolean;
  //Product Type Id Term Life 
  productTypeTermLifeId: string = "ea2c9fb5-e0db-ea11-a813-0022489d8bbc";
  //Product Type Id Saving
  productTypeSavingId: string = "ee5fe3f8-e0db-ea11-a813-0022489d8bbc";
  //product id
  productID:string;
  constructor() { this.visible = false }


  hideApplicantTab() { this.visible = false; }

  showApplicantTab() { this.visible = true; }

  enableMiscallaneous() { this.showMiscallaneous = true; }

  disableMiscallaneous() { this.showMiscallaneous = false; }

  setActiveTab(val) { this.active = val }


  ///////////////////////////////////////////////////////////
  objectToQueryString(a) {
    var prefix, s, add, name, r20, output;
    s = [];
    r20 = /%20/g;
    add = function (key, value) {
      // If value is a function, invoke it and return its value
      value = (typeof value == 'function') ? value() : (value == null ? "" : value);
      s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    };
    if (a instanceof Array) {
      for (name in a) {
        add(name, a[name]);
      }
    } else {
      for (prefix in a) {
        this.buildParams(prefix, a[prefix], add);
      }
    }
    output = s.join("&").replace(r20, "+");
    return output;
  };
  buildParams(prefix, obj, add) {
    var name, i, l, rbracket;
    rbracket = /\[\]$/;
    if (obj instanceof Array) {
      for (i = 0, l = obj.length; i < l; i++) {
        if (rbracket.test(prefix)) {
          add(prefix, obj[i]);
        } else {
          this.buildParams(prefix + "[" + (typeof obj[i] === "object" ? i : "") + "]", obj[i], add);
        }
      }
    } else if (typeof obj == "object") {
      // Serialize object item.
      for (name in obj) {
        this.buildParams(prefix + "." + name, obj[name], add);
      }
    } else {
      // Serialize scalar item.
      add(prefix, obj);
    }
  }
}
