import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonService } from '../_services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigConstants } from '../constants/config-constants';
import { BenefitProduct } from '../Model/dataModel';
@Component({
  selector: 'app-benefit-product-rider',
  templateUrl: './benefit-product-rider.component.html',
  styleUrls: ['./benefit-product-rider.component.css']
})
export class BenefitProductRiderComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  contactId: string;
  productId: string;
  productType: string;
  agentId: string;
  appId: string;
  productFilterFormGroup: FormGroup;
  productRiderFormGroup: FormGroup;
  maxPolicyTerm: number;
  minPolicyTerm: number;
  minPaymentTerm: number;
  maxPaymentTerm: number;
  policyTermValue: number;
  paymentTermValue: number;

  queryString: string;
  benefitProductDM: BenefitProduct;

  riders:any
  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private router: Router,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    public _dialog: MatDialog, private _common: CommonService, private _snackbar: MatSnackBar) {

    route.params.subscribe((data: any) => {
    
      this.contactId = data.contactId;
      this.productId = data.productId;
      this.productType = data.productType;
      this.agentId = data.agentId;
      this.appId = data.appId;
    });
    this.route.data.subscribe(data => {
     
      this._common.setActiveTab(data.id);
    });

  }

  ngOnInit(): void {
    this.benefitProductDM = new BenefitProduct();
    
    this.productFilterFormGroup = this._formBuilder.group({
      sumCovered: ['', Validators.required],
      rateClass: ['', Validators.required],
      policyTerm: ['', Validators.required],
      frequency: ['', Validators.required],
      currency: ['d009d7f5-bdd3-ea11-a813-000d3ab85fa8', Validators.required],
      paymentmode: ['', Validators.required],
      paymentTerm: [''],
      premiumBaseAmount: [''],
      applicationFee:['']
     
    });
    this.productRiderFormGroup=this._formBuilder.group({
       sumCoveredRider: [''],
      policyTermRider: [''],
      sumCoveredRider1: [''],
      policyTermRider1: [''],
      sumCoveredRider2: [''],
      policyTermRider2: [''],
      sumCoveredRider3: [''],
      policyTermRider3: [''],
      premiumAmountRider: [''],
      premiumAmountRider1: [''],
      premiumAmountRider2: [''],
      premiumAmountRider3: [''],
    
    });
    this.getApplicationDetailsByIs()
    this.GetProductByProductId();
   
    this.GetProductRiders();
  }

  obj: any;

  GetPremium() {
    
    if (this.productFilterFormGroup.valid) {
      var appData = this.productFilterFormGroup.value;

      this.obj = new Object();
  this.obj.benefitAmount = appData.sumCovered
      this.obj.rateClass = parseInt(appData.rateClass)
      this.obj.term = parseInt(appData.policyTerm)
      this.obj.paymentFrequency = parseInt(appData.frequency)
      this.obj.productType = this._common.productTypeTermLifeId
      this.obj.currency = appData.currency
      //this.obj.birthDate = "1980-08-10"
      //this.obj.effectiveDate = "2020-09-30"
      //this.obj.gender = "610900000"
      this.queryString = this._common.objectToQueryString(this.obj);
      this.blockUI.start();
      this._apiService.getProductPremiumQuery(this.queryString).subscribe((data: any) => {
        this.benefitProductDM.premiumBaseAmount = data.premium;
        this.benefitProductDM.applicationFee = data.applicationFee;
        this.blockUI.stop();
    
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
      });
    }
  }
  policyTermtLabel(value: number) {
   return value;
  }
  paymentTermLabel(value: number) {
    return value;
  } policyTermRiderLabel(value: number) {
    return value;
  }
  policyTermRiderLabel1(value: number) {
    return value;
  } policyTermRiderLabel2(value: number) {
    return value;
  } policyTermRiderLabel3(value: number) {
    return value;
  }
  getApplicationDetailsByIs() {
  
    this.blockUI.start();
    this._apiService.getApplicationById(this.appId).subscribe((data: any) => {

   debugger;
      this.blockUI.stop();
      this.productFilterFormGroup.get('sumCovered').setValue(data.benefitAmount);
      this.productFilterFormGroup.get('rateClass').setValue(data.rateClass.toString());
      this.productFilterFormGroup.get('policyTerm').setValue(data.term.toString());
      this.productFilterFormGroup.get('frequency').setValue(data.paymentFrequency.toString());
      this.productFilterFormGroup.get('currency').setValue(data.currency);
      this.productFilterFormGroup.get('paymentmode').setValue(data.paymentMode.toString());

    
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });

  }
  GetProductRiders() {
    
    this.blockUI.start();
    this._apiService.getProductRidersByProductId(this._common.productID).subscribe((data: any) => {

      debugger;
      this.blockUI.stop();
      this.riders = data.productRidersList;
  
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });
  }
  GetProductByProductId() {

    this.blockUI.start();
    this._apiService.getProductByProductId(this._common.productID).subscribe((data: any) => {

      debugger;
      this.blockUI.stop();
      this.minPaymentTerm = data.minPaymentTerm;
      this.maxPaymentTerm = data.maxPaymentTerm;
      this.maxPolicyTerm = data.maxPolicyTerm;
      this.minPolicyTerm = data.minPolicyTerm;
    }, err => {
      this.blockUI.stop();
      this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
    });
  }

  GetRiderPremium() {
    var data = this.benefitProductDM;
    debugger
    if (this.productRiderFormGroup.valid) {
      var appData = this.productRiderFormGroup.value;
         this.obj = new Object();

  
         this.obj.productId = this._common.productID
      this.obj.benefitAmount = appData.sumCoveredRider
      this.obj.term = appData.policyTermRider
      this.obj.paymentFrequency = parseInt(this.benefitProductDM.frequency)
      this.obj.currencyId = this.benefitProductDM.currency

      this.obj.riderId = this.riders[0].product.id;
       //this.obj.birthDate = "1980-08-10"
   //this.obj.gender = "610900000"
      this.queryString = this._common.objectToQueryString(this.obj);
      
      this.blockUI.start();
      this._apiService.getProductRidersPremium(this.queryString).subscribe((data: any) => {
        debugger
        this.benefitProductDM.premiumAmountRider = data.riderPremium;
        this.blockUI.stop();
       
     
      }, err => {
        this.blockUI.stop();
        this._snackbar.open('Something went wrong. Please contact administrator', 'Ok', ConfigConstants.snackBarConfig);
      });
    }
  }
 
  checkSumCover(value: any)
  {
    if (this.benefitProductDM.sumCovered> value) { }
    else {
    
      this._snackbar.open('rider sum cover can not greater than base sum cover', 'Ok', ConfigConstants.snackBarConfig);
    }
  }

  
}
